import State from '@/app/types/state';
import { REQUEST_DOCUMENTS_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

export const getItem = (state: State) => state.backoffice.requests.singleRequest.compareQuote.checkedQuoteIds;
export const getDocuments = (state: State) => state.backoffice.requests.singleRequest.request.value?.documents;

export const quotesItems = createSelector([getDocuments], (documents) =>
  documents?.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE)
);

export default createSelector([getItem], (item) => ({
  item,
}));
