import { StatusV2Enum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (status: StatusV2Enum): Record<string, SxProps<Theme>> => {
  let bar1Color: string;
  let bar2Color: string;
  let bar3Color: string;

  switch (status) {
    case StatusV2Enum.BEING_DIGITIZED:
      bar1Color = COLOURS.Fig.Solid[600];
      bar2Color = COLOURS.Fig.Solid[300];
      bar3Color = COLOURS.Fig.Solid[300];
      break;

    case StatusV2Enum.WAITING_FOR_APPROVAL:
      bar1Color = COLOURS.Banana.Solid[300];
      bar2Color = COLOURS.Banana.Solid[600];
      bar3Color = COLOURS.Banana.Solid[300];
      break;

    case StatusV2Enum.APPROVED:
    case StatusV2Enum.PAID:
      bar1Color = COLOURS.Salad.Solid[300];
      bar2Color = COLOURS.Salad.Solid[300];
      bar3Color = COLOURS.Salad.Solid[600];
      break;

    default:
      bar1Color = COLOURS.Coconut.Solid[500];
      bar2Color = COLOURS.Coconut.Solid[500];
      bar3Color = COLOURS.Coconut.Solid[500];
  }

  return {
    declinedRow: {
      height: 4,
      borderRadius: 1,
      backgroundColor: COLOURS.Strawberry.Solid[600],
    },

    row: {
      height: 4,
    },

    bar1: {
      borderRadius: 1,
      flex: 1,
      backgroundColor: bar1Color,
    },

    bar2: {
      borderRadius: 1,
      flex: 1,
      backgroundColor: bar2Color,
    },

    bar3: {
      borderRadius: 1,
      flex: 1,
      backgroundColor: bar3Color,
    },
  };
};
