import { getParams } from '@core-ui/redux-router';
import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { GET } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { ISagaContext } from '@/app/types/common';
import { myocean__routes__wallets__wallet__OrderFields, WalletContentSchema } from '@/generated';
import { WALLETS_PATHS } from '@/pages/backoffice/Wallets/routes';
import { ISingleWalletFiltersState } from '@/pages/backoffice/Wallets/SingleWalletFilters/reducer';
import { getSingleWalletFilters } from '@/pages/backoffice/Wallets/SingleWalletFilters/selector';
import { getPaginationPage, getSingleWalletSortOrder } from '@/pages/backoffice/Wallets/SingleWalletList/selector';
import { SingleWalletPathParams } from '@/pages/backoffice/Wallets/types';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 50;

interface ISortAndOrderDesc {
  orderBy: Nullable<myocean__routes__wallets__wallet__OrderFields>;
  order: SORT_ORDER_ENUM;
}

interface IFetchItems {
  page: number;
}

function* fetchItems({ page }: IFetchItems) {
  const history: ISagaContext['history'] = yield getContext('history');
  const params = getParams<SingleWalletPathParams>(history.location.pathname, WALLETS_PATHS);

  const { search, dateTo, dateFrom, uploadedBy, status }: ISingleWalletFiltersState = yield select(
    getSingleWalletFilters
  );
  const { orderBy, order }: ISortAndOrderDesc = yield select(getSingleWalletSortOrder);

  const requestUrl = buildURL(
    `/wallets/${params.walletId}/list`,
    {
      page,
      per_page: LIMIT,
      order_by: orderBy || undefined,
      is_desc: order === SORT_ORDER_ENUM.DESC,
      search,
      date_from: dateFrom ? Number(dateFrom) : undefined,
      date_to: dateTo ? Number(dateTo) : undefined,
      uploaded_by: uploadedBy || undefined,
      filter_statuses: status || undefined,
    },
    {
      arrayFormat: 'none',
    }
  );

  const documents: WalletContentSchema = yield call(GET, requestUrl);

  return documents;
}

function* getSingleWalletList() {
  try {
    const documents: WalletContentSchema = yield fetchItems({ page: 0 });

    yield all([
      put(actions.setPaginationPage(0)),
      put(actions.setTotal(documents.total ?? 0)),
      put(
        actions.setSingleWalletList({
          value: documents.records,
          hasData: Boolean(documents.records.length),
          isAllDataReceived: documents.records.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setSingleWalletList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* getChunkSingleWalletList() {
  try {
    const page: number = yield select(getPaginationPage);
    const newItems: WalletContentSchema = yield fetchItems({ page: page + 1 });

    yield all([
      put(actions.setPaginationPage(page + 1)),
      put(actions.setTotal(newItems.total ?? 0)),
      put(
        actions.setChunkSingleWalletList({
          value: newItems.records,
          isAllDataReceived: newItems.records.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getSingleWalletList, getSingleWalletList),
  takeLatest(actions.getChunkSingleWalletList, getChunkSingleWalletList),
];
