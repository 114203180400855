import singleRequest, { ISingleRequestState } from '@/pages/backoffice/Requests/SingleRequest/reducer';
import createEditRequestPanel, {
  ICreateEditRequestPanelState,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/reducer';
import filters, { IRequestsFiltersState } from 'pages/backoffice/Requests/RequestsFilters/reducer';
import list, { IRequestList } from 'pages/backoffice/Requests/RequestsList/reducer';
import viewRequestPanel, { IViewRequestPanelState } from 'pages/backoffice/Requests/ViewRequestPanel/reducer';
import { combineReducers } from 'redux';

export interface IRequestsState {
  filters: IRequestsFiltersState;
  list: IRequestList;
  createEditRequestPanel: ICreateEditRequestPanelState;
  viewRequestPanel: IViewRequestPanelState;

  // legacy
  singleRequest: ISingleRequestState;
}

export default combineReducers({
  filters,
  list,
  createEditRequestPanel,
  viewRequestPanel,

  // legacy
  singleRequest,
});
