import { IQuote } from '@/pages/backoffice/Requests/types';
import { Action, handleActions } from 'redux-actions';
import Immutable, { asMutable } from '@core-ui/immutable';
import { IQuoteChecked } from '../types';
import * as actions from './actions';

export interface ICompareQuoteState {
  checkedQuoteIds: Record<number, IQuoteChecked>;
  quotes: IQuote[];
  selectedQuoteIds: number[];
}

const defaultState = Immutable<ICompareQuoteState>({
  checkedQuoteIds: {},
  quotes: [],
  selectedQuoteIds: [],
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setCompareQuotes.toString()]: (state, { payload }: Action<actions.ISetCompareQuotes>) => {
      return state.merge({ checkedQuoteIds: payload.checkedQuoteIds, quotes: payload.quotes });
    },
    [actions.setRadioCheckedQuotesById.toString()]: (
      state,
      { payload }: Action<actions.IRadioCheckedCompareQuotes>
    ) => {
      let ids = asMutable(state.selectedQuoteIds);
      const foundId = ids.find((el) => el === payload.id);
      if (foundId) {
        ids = ids.filter((id) => id !== payload.id);
      } else {
        ids.push(payload.id);
      }

      return state.set('selectedQuoteIds', ids);
    },
    [actions.setCheckedQuoteById.toString()]: (state, { payload }: Action<actions.ICheckedCompare>) => {
      return state.setIn(['checkedQuoteIds', payload.id], { checked: payload.checked });
    },
    [actions.deleteQuoteInCompareView.toString()]: (state, { payload }: Action<number>) => {
      const quotes = state.quotes.filter((quote) => quote.id !== payload);

      return state.setIn(['checkedQuoteIds', payload], { checked: false }).set('quotes', quotes);
    },
    [actions.compareQuotes.toString()]: (state, { payload }: Action<actions.ICompareQuotes>) => {
      const quotes: IQuote[] = [];

      payload.quotes.forEach((quote) => {
        const checked = state.checkedQuoteIds[quote.id].checked;
        if (checked) {
          quotes.push(quote);
        }
      });

      return state.set('quotes', quotes);
    },
  },
  defaultState
);
