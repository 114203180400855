import { ScrollMemory } from '@core-ui/redux-router';
import NoDataContainer from 'components/NoDataContainer';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from 'components/NoFiltratedDataPlaceholder';
import TableSkeleton from 'components/TableSkeleton';
import { useAppSelector } from 'hooks/useAppSelector';
import { resetRequestFilters, updateRequestsFiltersQuery } from 'pages/backoffice/Requests/RequestsFilters/actions';
import { getRequestFiltersApplied } from 'pages/backoffice/Requests/RequestsFilters/selector';
import { getRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { RequestListTable } from 'pages/backoffice/Requests/RequestsList/components';
import { getNoDataContainerData } from 'pages/backoffice/Requests/RequestsList/selector';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import useStyles from './styles';

const SCROLLABLE_CONTAINER_ID = 'request-list';

const RequestsList = () => {
  const isFiltersApplied = useAppSelector(getRequestFiltersApplied);

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetRequestFilters());
      dispatch(updateRequestsFiltersQuery());
      dispatch(getRequestList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder titleId="requests.no_data.title" subtitleId="requests.no_data.subtitle" />
  );

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        sx={styles.noDataContainer}
        selector={getNoDataContainerData}
        loader={<TableSkeleton rows={10} />}
        noDataContent={noDataContent}
        id={SCROLLABLE_CONTAINER_ID}
      >
        <RequestListTable />
      </NoDataContainer>
    </>
  );
};

export default RequestsList;
