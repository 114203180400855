import { IOption } from '@core-ui/types';
import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getCurrencyCodeById } from '@/dictionary/selector';
import { getWalletsFilters } from '@/pages/backoffice/Wallets/WalletsFilters/selector';
import { createSelector } from 'reselect';

const getWalletItems = (state: State) => state.backoffice.wallets.list.value;

export const getWalletsOptions = createSelector(
  [getWalletItems],
  (wallets) => wallets?.map((item) => ({ value: item.id, label: item.name })) as IOption[]
);

const getWalletList = (state: State) => state.backoffice.wallets.list;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getWalletList], (walletsList) => ({
  loading: walletsList.loading,
  loaded: walletsList.loaded,
  hasData: walletsList.hasData,
  error: walletsList.error,
}));

const getChunkLoading = (state: State) => state.backoffice.wallets.list.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.wallets.list.isAllDataReceived;

export const chunkLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);

const getOrderBy = (state: State) => state.backoffice.wallets.list.orderBy;
const getSortDesc = (state: State) => state.backoffice.wallets.list.order;

export const getSortAndOrder = createSelector([getOrderBy, getSortDesc], (orderBy, order) => ({
  orderBy,
  order,
}));

export const getPaginationPage = (state: State) => state.backoffice.wallets.list.paginationPage;

const getFiltersApplied = createSelector([getWalletsFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector(
  [getWalletList, getAmountFormatter, getCurrencyCodeById, getFiltersApplied],
  (walletList, amountFormatter, getCurrencyCode, isFiltersApplied) => ({
    items: walletList.value,
    amountFormatter,
    getCurrencyCode,
    isFiltersApplied,
  })
);
