import { COLOURS } from '@core-ui/styles';
import { tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (isItemSelected: boolean): Record<string, SxProps<Theme>> => ({
  tableRow: {
    cursor: 'pointer',

    [`&.${tableRowClasses.root}>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: isItemSelected ? COLOURS.Fig.Solid[100] : undefined,
      borderColor: isItemSelected ? COLOURS.Fig.Solid[200] : undefined,
    },
    [`&.${tableRowClasses.root}:nth-of-type(even)>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: isItemSelected ? COLOURS.Fig.Solid[100] : undefined,
      borderColor: isItemSelected ? COLOURS.Fig.Solid[200] : undefined,
    },
    [`&.${tableRowClasses.root}:hover>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      backgroundColor: isItemSelected ? COLOURS.Fig.Solid[100] : undefined,
      borderColor: isItemSelected ? COLOURS.Fig.Solid[200] : undefined,
    },
  },

  nameCell: {
    overflowWrap: 'anywhere',
    cursor: 'pointer',
  },

  cell: {
    // substitute for deprecated word-break: break-word rule
    overflowWrap: 'break-word',
  },
});
