import { getCostArticleMappingEnabled } from 'app/selectors';
import { getRequestListSortOrder } from 'pages/backoffice/Requests/RequestsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getCostArticleMappingEnabled, getRequestListSortOrder],
  (costArticleMappingEnabled, requestSortOrder) => ({
    costArticleMappingEnabled: Boolean(costArticleMappingEnabled),
    orderBy: requestSortOrder.orderBy,
    order: requestSortOrder.order,
  })
);
