import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FileDashed, Trash } from '@phosphor-icons/react';
import { deleteCreateEditRequestFileByIndex } from 'pages/backoffice/Requests/CreateEditRequestPanel/actions';
import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  title?: string;
  index: number;
  loading?: boolean;
  alreadyUploaded?: boolean;
}

const AttachedDocumentsListItem = (props: IProps) => {
  const { title, index, loading, alreadyUploaded } = props;

  const dispatch = useDispatch();
  const styles = useStyles();

  // prevent dropzone from opening file upload window on item click
  const handleItemClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const handleDeleteFile = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(deleteCreateEditRequestFileByIndex(index));
  };

  return (
    <Grid item container sx={styles.container} onClick={handleItemClick}>
      <Grid item>
        <SvgIcon fontSize="large">
          <FileDashed />
        </SvgIcon>
      </Grid>

      <Grid item>
        <Typography fontWeight="semiBold">{title}</Typography>
      </Grid>

      {!alreadyUploaded && (
        <Grid item container width="auto" marginLeft="auto" alignItems="center">
          <Divider flexItem sx={styles.divider} orientation="vertical" />

          <Button
            variant="text"
            onClick={handleDeleteFile}
            disabled={loading}
            icon={<Trash color={COLOURS.Strawberry.Solid[600]} />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AttachedDocumentsListItem;
