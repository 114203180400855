import { useQuery } from '@core-ui/redux-router';
import { useAppSelector } from 'hooks/useAppSelector';
import isNil from 'lodash-es/isNil';
import { SingleRequestSidePanel } from 'pages/backoffice/Requests/components';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import { getSingleRequest } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { ViewRequestPanelContent, ViewRequestPanelHeader } from 'pages/backoffice/Requests/ViewRequestPanel/components';
import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import selector from './selector';

const ViewRequestPanel = () => {
  const { storedRequest, storedRequestId } = useAppSelector(selector);

  const dispatch = useDispatch();
  const query = useQuery<IRequestsQueryParams>();

  const panelClosed = query.panelMode !== 'view';

  useEffect(() => {
    batch(() => {
      if (!panelClosed && !isNil(query.selectedRequestId) && query.selectedRequestId !== storedRequestId) {
        dispatch(getSingleRequest({ id: query.selectedRequestId }));
      }
    });
  }, [panelClosed, query.selectedRequestId, storedRequest]);

  return (
    <SingleRequestSidePanel panelClosed={panelClosed}>
      <ViewRequestPanelHeader />
      <ViewRequestPanelContent />
    </SingleRequestSidePanel>
  );
};

export default ViewRequestPanel;
