import { GET, POST, PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { showNotification } from '@/components/Notification/actions';
import { closeBackofficeModal } from '@/pages/backoffice/Modals/actions';
import {
  FILE_UPLOAD_TYPE,
  INVOICE_FILE_UPLOAD_TYPE_ENUM,
  IOpenLayerFeature,
  IPaymentRequest,
} from '@/pages/backoffice/Requests/types';
import { Action } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { updateQuotes } from '../QuotesCompare/actions';
import * as actions from './actions';

function* getPaymentRequest({ payload }: Action<actions.IGetPaymentRequest>) {
  try {
    const { requestId } = payload;

    const response: IPaymentRequest = yield call(GET, `requests/${requestId}`);

    const features: IOpenLayerFeature[] =
      // TODO: пофиксить ошибку типа когда будем переезжать на сгенеренные интерфейсы
      // @ts-ignore
      response.map_points?.map((p) => ({
        coordinates: p,
        request_id: response.id,
      })) ?? [];
    response.features = features;

    yield put(
      actions.setRequest({
        value: response,
        hasData: false,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(actions.setRequest({ error: e as Error, hasData: false }));
  }
}

const createFormData = (
  files: File[],
  requestId: string | number,
  docType: INVOICE_FILE_UPLOAD_TYPE_ENUM | FILE_UPLOAD_TYPE
) => {
  if (!files || !files.length) {
    return null;
  }
  const formData = new FormData();
  formData.append('request_id', requestId as string);
  formData.append('doctype', docType);
  files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* uploadToDigitized({ payload }: Action<actions.IUploadDigitized>) {
  const { requestId, invoiceFiles, quoteFiles, requestFiles, invoiceFileSelectedType } = payload;

  try {
    const submitQueue = [
      // @ts-ignore
      createFormData(invoiceFiles, requestId, invoiceFileSelectedType),
      // @ts-ignore
      createFormData(quoteFiles, requestId, FILE_UPLOAD_TYPE.QUOTE),
      // @ts-ignore
      createFormData(requestFiles, requestId, FILE_UPLOAD_TYPE.DEFAULT),
    ].filter(Boolean);

    yield all(
      submitQueue.map((formData) =>
        // @ts-ignore
        call(POST, '/files', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      )
    );
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );
    yield put(actions.getRequest({ requestId }));
  } catch (e) {
    yield call(responseError, e);
  }
}

function* updateQuoteStatus({ payload }: Action<actions.IUpdateQuoteStatus>) {
  const { requestId, documentIds, status } = payload;

  try {
    yield put(actions.setLoadingButton({ id: requestId, loading: true }));

    yield call(
      PUT,
      '/approves',
      documentIds.map((id) => ({
        request_id: +requestId,
        document_id: id,
        status,
      }))
    );

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );

    yield put(actions.getRequest({ requestId }));
    yield take(actions.setRequest);
    yield put(updateQuotes());
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setLoadingButton({ id: requestId, loading: false }));
  }
}

function* QuoteAsInvoice({ payload }: Action<actions.IUseQuoteAsInvoice>) {
  const { requestId, documentId, invoiceType } = payload;
  try {
    yield call(POST, '/document/invoice', {
      document_id: documentId,
      invoice_type: invoiceType,
    });

    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );

    yield put(closeBackofficeModal());
    yield put(actions.getRequest({ requestId }));
  } catch (e) {
    yield call(responseError, e);
  }
}

function* sendToERP({ payload }: Action<actions.ISendToXero>) {
  const { requestId: request_id, clientCostArticleCode: invoice_code, invoiceDocumentId: document_id } = payload;
  try {
    yield put(actions.setERPLoading(true));

    const boat_id: number = yield select(vehicleIdSelector);

    // TODO: добавить возвращаемый тип когда будем переезжать на сгенеренные интерфейсы
    // @ts-ignore
    const response = yield call(POST, '/xero/invoice/send', {
      boat_id,
      request_id,
      invoice_code,
      document_id,
    });

    if (response.login_redirect_uri) {
      window.location = response.login_redirect_uri;
    }

    yield put(closeBackofficeModal());
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      showNotification({
        variant: 'error',
        titleId: 'notification.error.text.xero_default',
      })
    );
  } finally {
    yield put(actions.setERPLoading(false));
  }
}

export default [
  takeLatest(actions.getRequest, getPaymentRequest),
  takeLatest(actions.uploadToDigitized, uploadToDigitized),
  takeLatest(actions.updateQuoteStatus, updateQuoteStatus),
  takeLatest(actions.quoteAsInvoice, QuoteAsInvoice),
  takeLatest(actions.sendToERP, sendToERP),
];
