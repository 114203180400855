import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import ChunkLoader from 'components/ChunkLoader';
import { getChunkRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { RequestListTableBody, RequestListTableHeader } from 'pages/backoffice/Requests/RequestsList/components';
import { chunkRequestListLoaderSelector } from 'pages/backoffice/Requests/RequestsList/selector';
import React from 'react';
import useStyles from './styles';

const RequestsListTable = () => {
  const styles = useStyles();

  return (
    <Grid item sx={styles.container}>
      <Table stickyHeader>
        <RequestListTableHeader />
        <RequestListTableBody />
      </Table>

      <ChunkLoader selector={chunkRequestListLoaderSelector} getChunk={getChunkRequestList} />
    </Grid>
  );
};

export default RequestsListTable;
