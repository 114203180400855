import { SxProps, Theme } from '@mui/material/styles';
import { IBadgeColor } from 'app/types/common';

export default (badgeColor?: IBadgeColor): Record<string, SxProps<Theme>> => ({
  container: {
    width: 'fit-content',
    height: 'fit-content',
    paddingX: 1,
    borderRadius: 1,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    backgroundColor: badgeColor?.backgroundColor,
    color: badgeColor?.textColor,
  },
});
