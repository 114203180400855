import Grid from '@mui/material/Grid';
import NoDataContainer from 'components/NoDataContainer';
import React from 'react';
import { ViewRequestPanelContentDocuments, ViewRequestPanelContentInfo, ViewRequestPanelContentStepper } from '..';
import { noDataSelector } from './selector';
import useStyles from './styles';

const ViewRequestPanelContent = () => {
  const styles = useStyles();

  return (
    <NoDataContainer selector={noDataSelector}>
      <Grid item container sx={styles.container}>
        <ViewRequestPanelContentInfo />
        <ViewRequestPanelContentStepper />
        <ViewRequestPanelContentDocuments />
      </Grid>
    </NoDataContainer>
  );
};

export default ViewRequestPanelContent;
