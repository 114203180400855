import { getAmountFormatter, getAmountFormatterWithCurrencyCodeById } from '@/dictionary/selector';
import { getSingleWalletListState } from '@/pages/backoffice/Wallets/SingleWalletList/selector';
import { getSingleWallet } from '@/pages/backoffice/Wallets/SingleWalletRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getSingleWallet, getSingleWalletListState, getAmountFormatterWithCurrencyCodeById, getAmountFormatter],
  (singleWallet, documentsList, currencyFormatter, amountFormatter) => ({
    wallet: singleWallet.value,
    formattedTotal: singleWallet.value?.currency_id
      ? currencyFormatter(singleWallet.value?.currency_id, documentsList.total)
      : amountFormatter(documentsList.total),
  })
);
