import { ScrollMemory, useParams } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import ChunkLoader from '@/components/ChunkLoader';
import NoDataContainer from '@/components/NoDataContainer';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from '@/components/NoFiltratedDataPlaceholder';
import TableSkeleton from '@/components/TableSkeleton';
import { useAppSelector } from '@/hooks/useAppSelector';
import { WALLETS_PATHS } from '@/pages/backoffice/Wallets/routes';
import {
  resetSingleWalletFilters,
  updateSingleWalletFiltersQuery,
} from '@/pages/backoffice/Wallets/SingleWalletFilters/actions';
import { getChunkSingleWalletList, getSingleWalletList } from '@/pages/backoffice/Wallets/SingleWalletList/actions';
import SingleWalletPlaceholderRow from '@/pages/backoffice/Wallets/SingleWalletList/SingleWalletPlaceholderRow';
import SingleWalletRow from '@/pages/backoffice/Wallets/SingleWalletList/SingleWalletRow';
import TableHeader from '@/pages/backoffice/Wallets/SingleWalletList/TableHeaders';
import { SingleWalletPathParams } from '@/pages/backoffice/Wallets/types';
import React, { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import selector, { chunkLoaderSelector, getNoDataContainerData } from './selector';

const SCROLLABLE_CONTAINER_ID = 'single-wallet-list';

const noDataContainerStyle: SxProps<Theme> = {
  paddingX: 6,
};

const SingleWalletList: FC = () => {
  const { placeholders, items, amountFormatter, currencyCodeGetter, boatId, isFiltersApplied } =
    useAppSelector(selector);

  const params = useParams<SingleWalletPathParams>(WALLETS_PATHS);
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    batch(() => {
      dispatch(resetSingleWalletFilters());
      dispatch(updateSingleWalletFiltersQuery());
      dispatch(getSingleWalletList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleClearFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        selector={getNoDataContainerData}
        noDataContent={noDataContent}
        loader={<TableSkeleton rows={10} />}
        sx={noDataContainerStyle}
        id={SCROLLABLE_CONTAINER_ID}
      >
        <Grid item height="100%" width="100%">
          <Table stickyHeader>
            <TableHeader />

            <TableBody>
              {placeholders.map((placeholder) => (
                <SingleWalletPlaceholderRow key={placeholder} item={placeholder} />
              ))}

              {items?.map((item) => (
                <SingleWalletRow
                  key={item.id}
                  item={item}
                  walletId={Number(params.walletId)}
                  boatId={boatId}
                  amountFormatter={amountFormatter}
                  currencyCodeGetter={currencyCodeGetter}
                />
              ))}
            </TableBody>
          </Table>

          <ChunkLoader selector={chunkLoaderSelector} getChunk={getChunkSingleWalletList} />
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default SingleWalletList;
