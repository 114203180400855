import {
  getCreateEditFilenames,
  getCreateEditFiles,
  getCreateEditRequestLoading,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getCreateEditRequestLoading, getCreateEditFiles, getCreateEditFilenames],
  (loading, files, filenames) => ({
    loading,
    files,
    filenames,
  })
);
