import { RequestMinimalOutputV2Schema, RequestSortingColumnsV2Enum } from '@/generated';
import Immutable, { ImmutableObject } from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';
import { IAddItemToRequestListPayload, IUpdateItemInRequestListPayload } from './actions';

export interface IRequestList extends List<RequestMinimalOutputV2Schema> {
  orderBy: Nullable<RequestSortingColumnsV2Enum>;
  order: SORT_ORDER_ENUM;
  paginationPage: number;
}

const defaultState = Immutable<IRequestList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  orderBy: RequestSortingColumnsV2Enum.CREATED_AT,
  order: SORT_ORDER_ENUM.DESC,
  paginationPage: 0,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setOrderBy.toString()]: (state, { payload }: Action<RequestSortingColumnsV2Enum>) => {
      return state.set('orderBy', payload);
    },
    [actions.setOrder.toString()]: (state, { payload }: Action<SORT_ORDER_ENUM>) => {
      return state.set('order', payload);
    },
    [actions.setRequestListPaginationPage.toString()]: (state, { payload }: Action<number>) => {
      return state.set('paginationPage', payload);
    },
    [actions.addItemToRequestList.toString()]: (state, { payload }: Action<IAddItemToRequestListPayload>) => {
      const { item } = payload;
      const currentList = state.getIn(['value']) || [];

      return state.set('value', [item, ...currentList]);
    },
    [actions.updateItemInRequestList.toString()]: (state, { payload }: Action<IUpdateItemInRequestListPayload>) => {
      const { item } = payload;
      const currentList = state.getIn(['value']) || [];

      const itemIndex = currentList.findIndex((request) => request.id === item.id);

      if (itemIndex === -1) {
        return state;
      }

      const updatedItem: ImmutableObject<RequestMinimalOutputV2Schema> = {
        ...currentList[itemIndex],
        name: item.name,
        status: item.status,
        total_price: item.total_price,
      };

      return state.set('value', [...currentList.slice(0, itemIndex), updatedItem, ...currentList.slice(itemIndex + 1)]);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
