import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'wallets.single_wallet.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetSingleWalletFilters = resetState(reducerName);

export const updateSingleWalletFiltersQuery = createAction(`${reducerName}.UPDATE_SINGLE_WALLET_FILTERS_QUERY`);
export const setSingleWalletFiltersFromQuery = createParseQuery(reducerName);
