import { setRequiredFields } from 'app/utils/app';
import { ValidationErrors } from 'final-form';
import { ICreateEditRequestForm } from 'pages/backoffice/Requests/types';

export default (values: ICreateEditRequestForm): ValidationErrors => {
  const errors: ValidationErrors = {};

  setRequiredFields(values, errors, ['title', 'description']);

  return errors;
};
