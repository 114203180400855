import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import TextField from '@core-ui/text_field';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { useAppSelector } from '@/hooks/useAppSelector';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { CreateEditWalletModalData } from '@/pages/backoffice/Modals/command';
import { setTextFilter } from '@/pages/backoffice/Wallets/WalletsFilters/actions';
import { IWalletsFiltersState } from '@/pages/backoffice/Wallets/WalletsFilters/reducer';
import { WALLETS_TYPE_OPTIONS } from '@/pages/backoffice/Wallets/WalletsFilters/types';
import { getWalletsList, setWalletsListLoading } from '@/pages/backoffice/Wallets/WalletsList/actions';
import { MagnifyingGlass, PlusCircle } from '@phosphor-icons/react';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

const WalletsFilters: FC = () => {
  const { filters } = useAppSelector(selector);
  const dispatch = useDispatch();

  const setLoadingCallback = useCallback(
    () => dispatch(setWalletsListLoading({ loading: true })),
    [setWalletsListLoading]
  );

  const handleFilterChange = useFilter<IWalletsFiltersState>({
    getList: getWalletsList,
    setFilter: setTextFilter,
    onChange: setLoadingCallback,
  });

  const handleOpenCreateNewWalletModal = () => {
    dispatch(openBackofficeModal(new CreateEditWalletModalData(null, true)));
  };

  return (
    <Grid item container justifyContent="space-between" padding="24px 0" gap={3}>
      <Grid item width={326}>
        <TextField
          fullWidth
          size="medium"
          label={<FormattedMessage id="filters.search" />}
          // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
          // @ts-ignore
          onChange={handleFilterChange('name')}
          value={filters.name}
          rightIcon1={
            <SvgIcon>
              <MagnifyingGlass />
            </SvgIcon>
          }
        />
      </Grid>

      <Grid item width={270}>
        <Autocomplete
          limitTags={3}
          options={WALLETS_TYPE_OPTIONS}
          value={filters.types}
          label={<FormattedMessage id="label.wallet_type" />}
          onChange={handleFilterChange('types')}
          multiple
        />
      </Grid>

      <Grid item marginLeft="auto">
        <Button
          variant="contained"
          color="blue"
          size="large"
          startIcon={<PlusCircle />}
          onClick={handleOpenCreateNewWalletModal}
          label={<FormattedMessage id="label.create_new_wallet" />}
        />
      </Grid>
    </Grid>
  );
};

export default WalletsFilters;
