import { FormattedMessage } from 'components/Intl';
import { ITableColumn } from '@/pages/backoffice/types';

export const columns: ITableColumn[] = [
  {
    key: 'line_name',
    title: <FormattedMessage id="table.header.line_name" />,
  },
  {
    key: 'description',
    title: <FormattedMessage id="table.header.description" />,
  },
  {
    key: 'cost_article',
    title: <FormattedMessage id="table.header.cost_article_or_cost_center" />,
  },
  {
    key: 'qty',
    title: <FormattedMessage id="table.header.qty" />,
    align: 'right',
  },
  {
    key: 'vat',
    title: <FormattedMessage id="table.header.vat" />,
    align: 'right',
  },
  {
    key: 'currency',
    title: <FormattedMessage id="table.header.currency" />,
    align: 'right',
  },
  {
    key: 'subtotal',
    title: <FormattedMessage id="table.header.subtotal" />,
    align: 'right',
  },
  {
    key: 'total',
    title: <FormattedMessage id="table.header.total" />,
    align: 'right',
  },
];
