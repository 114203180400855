import Button from '@core-ui/button';
import { updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { NotePencil, X } from '@phosphor-icons/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { RequestStatusBadge } from 'pages/backoffice/Requests/components';
import {
  VIEW_PANEL_HEADER_SKELETON_HEIGHT,
  VIEW_PANEL_HEADER_STATUS_MAX_WIDTH,
  VIEW_PANEL_HEADER_TITLE_MAX_WIDTH,
} from 'pages/backoffice/Requests/consts';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import selector from './selector';
import useStyles from './styles';

const ViewRequestPanelHeader = () => {
  const { loading, disabled, requestName, requestId, requestStatus } = useAppSelector(selector);
  const history = useHistory();
  const styles = useStyles();

  const handleClosePanel = () => {
    // не делаем ресет при закрытии панели чтобы во время анимации закрытия не появлялся лоадер
    // вместо этого ресет выполняется при открытии панели и сразу за ним идёт загрузка новых данных
    history.replace(
      updateQuery<IRequestsQueryParams>({
        selectedRequestId: undefined,
        panelMode: undefined,
      })
    );
  };

  const handleOpenEditPanel = () => {
    history.replace(
      updateQuery<IRequestsQueryParams>({
        selectedRequestId: requestId,
        panelMode: 'edit',
      })
    );
  };

  const isButtonDisabled = loading || disabled;

  return (
    <Grid item container sx={styles.container}>
      <Grid item container sx={styles.titleContainer} wrap="nowrap">
        {loading ? (
          <Skeleton
            variant="rounded"
            width={VIEW_PANEL_HEADER_TITLE_MAX_WIDTH}
            height={VIEW_PANEL_HEADER_SKELETON_HEIGHT}
          />
        ) : (
          <Typography variant="h4" sx={styles.title}>
            {requestName}
          </Typography>
        )}
      </Grid>

      <Grid item container gap={1} width="auto" alignItems="center" wrap="nowrap">
        {loading ? (
          <Skeleton
            variant="rounded"
            width={VIEW_PANEL_HEADER_STATUS_MAX_WIDTH}
            height={VIEW_PANEL_HEADER_SKELETON_HEIGHT}
          />
        ) : (
          <RequestStatusBadge status={requestStatus} />
        )}

        <Button variant="text" onClick={handleOpenEditPanel} icon={<NotePencil />} disabled={isButtonDisabled} />
        <Button variant="filled" onClick={handleClosePanel} icon={<X />} disabled={isButtonDisabled} />
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelHeader;
