import walletsSingleWalletListSagas from '@/pages/backoffice/Wallets/SingleWalletList/sagas';
import singleWalletRowSagas from '@/pages/backoffice/Wallets/SingleWalletList/SingleWalletRow/sagas';
import singleWalletDragNDropSagas from '@/pages/backoffice/Wallets/SingleWalletDragNDrop/sagas';
import walletsSingleWalletFiltersSagas from '@/pages/backoffice/Wallets/SingleWalletFilters/sagas';
import walletsSingleWalletSagas from '@/pages/backoffice/Wallets/SingleWalletRoot/sagas';
import walletsFiltersSagas from '@/pages/backoffice/Wallets/WalletsFilters/sagas';
import walletsListSagas from '@/pages/backoffice/Wallets/WalletsList/sagas';

export default [
  ...walletsListSagas,
  ...walletsFiltersSagas,
  ...walletsSingleWalletSagas,
  ...walletsSingleWalletListSagas,
  ...singleWalletRowSagas,
  ...walletsSingleWalletFiltersSagas,
  ...singleWalletDragNDropSagas,
];
