import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  contentContainer: {
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
    backgroundColor: COLOURS.Coconut.Solid[50],
    borderRadius: 1,
    padding: 3,
  },
});
