import { DATE_FORMAT } from 'app/consts/app';
import { formatTime } from 'app/utils/dates';
import { getViewPanelStateValue } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';

export default createSelector([getViewPanelStateValue], (value) => ({
  createdAt: value?.created_at ? formatTime(value.created_at, { dateFormat: DATE_FORMAT }) : '',
  createdBy: value?.created_by_email
    ? value.created_by_email
    : [value?.created_by_first_name, value?.created_by_last_name].filter(Boolean).join(' ') || '',
  description: value?.description,
}));
