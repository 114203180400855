import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { DOCUMENT_ITEM_MIN_HEIGHT } from 'pages/backoffice/Requests/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    minHeight: DOCUMENT_ITEM_MIN_HEIGHT,
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 2,
    padding: 3,
    border: `1px solid ${COLOURS.Coconut.Solid[200]}`,
    borderRadius: 1,
    backgroundColor: COLOURS.WHITE,
  },

  divider: {
    height: '20px',
    alignSelf: 'center',
    marginRight: 2,
  },
});
