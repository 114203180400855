import * as actions from 'pages/backoffice/Requests/RequestsFilters/actions';
import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { handleActions } from 'redux-actions';

export interface IRequestsFiltersState {
  name: string;
}

const IRequestFilterState = Immutable<IRequestsFiltersState>({
  name: '',
});

export default handleActions({}, reducer<typeof IRequestFilterState>(actions.reducerName, IRequestFilterState));
