import { StatusV2Enum } from '@/generated';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useStyles from './styles';

interface IProps {
  status: StatusV2Enum;
}

const ProgressLine = (props: IProps) => {
  const { status } = props;
  const styles = useStyles(status);

  if (status === StatusV2Enum.DECLINED) {
    return <Box sx={styles.declinedRow} />;
  }

  return (
    <Grid item container gap={1} wrap="nowrap" sx={styles.row}>
      <Box sx={styles.bar1} />
      <Box sx={styles.bar2} />
      <Box sx={styles.bar3} />
    </Grid>
  );
};

export default ProgressLine;
