import invoiceSagas from './InvoicesList/sagas';
import compareSagas from './QuotesCompare/sagas';
import listSagas from './QuotesList/sagas';
import requestSagas from './SingleRequestRoot/sagas';

// TODO: не удалять эти саги до полного переезда на новые ручки

export default [
  ...listSagas, // quote list sagas
  ...invoiceSagas,
  ...compareSagas,
  ...requestSagas, // update request saga
];
