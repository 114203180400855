import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import { CashPaymentTypeEnum } from '@/generated';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IWalletsFiltersState {
  name: string;
  types: Nullable<IOption<CashPaymentTypeEnum>[]>;
}

const defaultState = Immutable<IWalletsFiltersState>({
  name: '',
  types: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
