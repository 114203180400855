import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import { WalletOutputSchema } from '@/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'wallets.list';

export const getWalletsList = getList(reducerName);
export const setWalletsList = setList<WalletOutputSchema>(reducerName);

export const getChunkWalletList = getChunkList(reducerName);
export const setChunkWalletList = setChunkList<WalletOutputSchema>(reducerName);

export interface IDeleteWallet {
  id: number;
  shouldRedirect: boolean;
}

export const deleteWallet = createAction<IDeleteWallet>(`${reducerName}.DELETE_WALLET`);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setOrder = createAction<SORT_ORDER_ENUM>(`${reducerName}.SET_ORDER`);

export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const setWalletsListLoading = setLoading(reducerName);

export const resetWalletsList = resetState(reducerName);
