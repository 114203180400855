import createEditRequestPanelSagas from 'pages/backoffice/Requests/CreateEditRequestPanel/sagas';
import filterSagas from 'pages/backoffice/Requests/RequestsFilters/sagas';
import listSagas from 'pages/backoffice/Requests/RequestsList/sagas';
import singleRequestSagas from './SingleRequest/sagas';
import viewRequestPanelSagas from './ViewRequestPanel/sagas';

// TODO: удалить импорт легаси singleRequestSagas, но сами саги удалять только после полного переезда на новые ручки
export default [
  ...filterSagas,
  ...listSagas,
  ...createEditRequestPanelSagas,
  ...viewRequestPanelSagas,

  // legacy
  ...singleRequestSagas,
];
