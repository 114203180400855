import {
  EmployeeSchema,
  RequestCreateOutputSchema,
  RequestDetailOutputV2Schema,
  RequestInputSchema,
  RequestMinimalOutputV2Schema,
  RequestPatchV2Schema,
  RequestTypeEnum,
  StatusV2Enum,
} from '@/generated';
import { Nullable } from '@core-ui/types';
import { updateQuery } from '@core-ui/url';
import { select } from '@redux-saga/core/effects';
import { GET, PATCH, POST } from 'api/oceanApi';
import { responseError } from 'app/sagas';
import { getAppUserSelector, vehicleIdSelector } from 'app/selectors';
import { ISagaContext } from 'app/types/common';
import { showNotification } from 'components/Notification/actions';
import isNil from 'lodash-es/isNil';
import { PANEL_FORM } from 'pages/backoffice/Requests/CreateEditRequestPanel/components/CreateEditRequestPanelForm/CreateEditRequestPanelForm';
import {
  getCreateEditFilenames,
  getCreateEditRequestInitialValue,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { addItemToRequestList, updateItemInRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import {
  ICreateEditRequestForm,
  IFilesPostResponse,
  INVOICE_FILE_UPLOAD_TYPE_ENUM,
  IRequestsQueryParams,
} from 'pages/backoffice/Requests/types';
import { getFilenamesFromFilesTree } from 'pages/backoffice/Requests/utils';
import { setSingleRequestFromCreateEditPanelModule } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import { Action } from 'redux-actions';
import { all, call, getContext, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getCreateEditRequest({ payload }: Action<actions.IGetCreateEditRequest>) {
  try {
    const { id } = payload;
    const boatId: number = yield select(vehicleIdSelector);

    const request: RequestDetailOutputV2Schema = yield call(GET, `/v2/requests/${id}`, {
      boat_id: boatId,
    });

    const value: ICreateEditRequestForm = {
      id: request.id,
      title: request.name,
      description: request.description,
    };

    const filenames = getFilenamesFromFilesTree(request?.files_tree);

    yield all([
      put(
        actions.setCreateEditRequest({
          value,
          hasData: true,
        })
      ),
      put(actions.setCreateEditRequestInitialValue(value)),
      put(actions.setCreateEditRequestFilenames(filenames)),
      put(setSingleRequestFromCreateEditPanelModule({ request })),
    ]);
  } catch (e) {
    yield all([
      put(
        actions.setCreateEditRequest({
          hasData: false,
          error: e as Error,
        })
      ),
      call(responseError, e),
    ]);
  }
}

function* setCreateEditRequestFromViewPanel({ payload }: Action<actions.ISetCreateEditRequestFromViewPanel>) {
  const { request } = payload;

  const value: ICreateEditRequestForm = {
    id: request.id,
    title: request.name,
    description: request.description,
  };

  const filenames = getFilenamesFromFilesTree(request?.files_tree);

  yield all([
    put(
      actions.setCreateEditRequest({
        value,
        hasData: true,
      })
    ),
    put(actions.setCreateEditRequestInitialValue(value)),
    put(actions.setCreateEditRequestFilenames(filenames)),
  ]);
}

interface IUploadFilesToRequest {
  requestId: number;
  files: File[];
}

const createFormData = (payload: IUploadFilesToRequest) => {
  const formData = new FormData();

  // TODO: upload all new files as prepayment invoices until we add a Quote flow to the Requests
  formData.append('doctype', INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT);
  formData.append('request_id', String(payload.requestId));

  payload.files.forEach((file) => formData.append('fileobjects', file));

  return formData;
};

function* uploadFilesToRequest(payload: IUploadFilesToRequest) {
  try {
    const { requestId, files } = payload;
    const formData = createFormData({ requestId, files });

    const response: IFilesPostResponse = yield call(POST, '/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (e) {
    yield call(responseError, e);
  }
}

function* createRequest({ payload }: Action<actions.ICreateRequest>) {
  try {
    yield put(actions.setCreateEditRequestDisabled(true));

    const { formValues, files } = payload;
    const boatId: number = yield select(vehicleIdSelector);
    const history: ISagaContext['history'] = yield getContext('history');

    if (isNil(formValues.title) || isNil(formValues.description)) {
      throw new Error('Title and description must be filled');
    }

    const body: RequestInputSchema = {
      boat_id: boatId,
      name: formValues.title,
      description: formValues.description,

      // TODO: временный хардкод кост центра и типа реквеста, удалим после переезда на новую ручку
      cost_centers_ids: [2], // id 2 - это main_boat
      request_type: RequestTypeEnum.DEFAULT,
    };

    const newRequest: RequestCreateOutputSchema = yield call(POST, '/requests', body);

    yield call(uploadFilesToRequest, { requestId: newRequest.id, files });

    // TODO: удалить при переезде на новую ручку создания реквеста, она должна сама отдавать все необходимые данные юзера
    const user: EmployeeSchema = yield select(getAppUserSelector);

    // TODO: обновить этот объект при переезде на новую ручку создания реквеста
    //  а мб этот маппинг вообще станет ненужным
    const listItem: RequestMinimalOutputV2Schema = {
      id: newRequest.id,
      name: newRequest.name,
      status: StatusV2Enum.BEING_DIGITIZED,
      created_at: newRequest.created_at,
      created_by_id: user.id,
      created_by_last_name: user.last_name,
      created_by_first_name: user.first_name,
      created_by_email: user.email,
      vendors: undefined,
      cost_articles: undefined,
      currency_id: newRequest.amount_currency_id,
      total_price: newRequest.amount,
    };

    yield all([
      put(addItemToRequestList({ item: listItem })),
      // TODO: добавить этот экшен при переезде на новую ручку POST, сейчас это сделать нельзя из-за нехватки данных в схеме RequestCreateOutputSchema
      //  также непонятно как быть с файлами, т.к. они загружаются в реквест после его создания, то в ответе ручки POST их в не может быть
      // put(setSingleRequestFromCreateEditPanelModule({ request: newRequest })),
      put(actions.resetCreateEditRequestPanelState()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.create_request',
        })
      ),
    ]);

    history.replace(
      updateQuery<IRequestsQueryParams>({
        selectedRequestId: newRequest.id,
        panelMode: 'view',
      })
    );
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setCreateEditRequestDisabled(false));
  }
}

function* updateRequest({ payload }: Action<actions.IUpdateRequest>) {
  try {
    let isError = false;

    yield put(actions.setCreateEditRequestDisabled(true));

    const { formValues, files } = payload;
    const boatId: number = yield select(vehicleIdSelector);
    const history: ISagaContext['history'] = yield getContext('history');

    if (files && files?.length > 0 && formValues?.id) {
      const response: IFilesPostResponse = yield call(uploadFilesToRequest, {
        requestId: formValues.id,
        files,
      });

      if (response?.success) {
        const currentFilenames: string[] = yield select(getCreateEditFilenames);
        const updatedFilenames: string[] = [...currentFilenames, ...files.map((file) => file.name)];

        yield all([
          put(actions.clearCreateEditRequestFiles()),
          put(actions.setCreateEditRequestFilenames(updatedFilenames)),
        ]);
      } else {
        isError = true;

        yield put(
          showNotification({
            variant: 'error',
            titleId: 'notification.error.text.files_upload',
          })
        );
      }
    }

    if (!isError) {
      const url = `/v2/requests/${formValues.id}?boat_id=${boatId}`;
      const body: RequestPatchV2Schema = {
        name: formValues.title,
        description: formValues.description,
      };

      const updatedRequest: RequestDetailOutputV2Schema = yield call(PATCH, url, body);

      const updatedValue: ICreateEditRequestForm = {
        id: updatedRequest.id,
        title: updatedRequest.name,
        description: updatedRequest.description,
      };

      yield all([
        put(
          actions.setCreateEditRequest({
            value: updatedValue,
            hasData: true,
          })
        ),
        put(setSingleRequestFromCreateEditPanelModule({ request: updatedRequest })),
        put(updateItemInRequestList({ item: updatedRequest })),
        put(
          showNotification({
            variant: 'success',
            titleId: 'notification.success.text.update_request',
          })
        ),
      ]);

      history.replace(
        updateQuery<IRequestsQueryParams>({
          selectedRequestId: updatedRequest.id,
          panelMode: 'view',
        })
      );
    }
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setCreateEditRequestDisabled(false));
  }
}

function* clearCreateEditRequestPanelFormValues() {
  PANEL_FORM?.batch(() => {
    PANEL_FORM?.restart();
  });
}

function* resetCreateEditRequestPanelFormValuesToInitial() {
  const initialValue: Nullable<ICreateEditRequestForm> = yield select(getCreateEditRequestInitialValue);

  PANEL_FORM?.batch(() => {
    PANEL_FORM?.change('title', initialValue?.title);
    PANEL_FORM?.change('description', initialValue?.description);
  });
}

export default [
  takeLatest(actions.getCreateEditRequest, getCreateEditRequest),
  takeLatest(actions.setCreateEditRequestFromViewPanel, setCreateEditRequestFromViewPanel),
  takeLatest(actions.createRequest, createRequest),
  takeLatest(actions.updateRequest, updateRequest),
  takeLatest(actions.resetCreateEditRequestPanelState, clearCreateEditRequestPanelFormValues),
  takeLatest(actions.resetCreateEditRequestPanelFormValuesToInitial, resetCreateEditRequestPanelFormValuesToInitial),
];
