import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FilePlus } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import LoadFileDropzone from 'components/LoadFileDropzone';
import { useAppSelector } from 'hooks/useAppSelector';
import { uploadFiles } from 'pages/backoffice/Requests/ViewRequestPanel/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import selector from './selector';

const ViewRequestPanelContentDocumentsHeader = () => {
  const { dropzoneDisabled } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleDropFiles = (files: File[]) => {
    dispatch(uploadFiles({ files }));
  };

  return (
    <Grid item container justifyContent="space-between" wrap="nowrap">
      <Typography variant="h4">
        <FormattedMessage id="label.documents" />
      </Typography>

      <LoadFileDropzone
        disabled={dropzoneDisabled}
        reducerName="test_reducer"
        handleOnDrop={handleDropFiles}
        render={(props, { getRootProps, getInputProps }) => (
          <Grid container alignItems="center" width="auto" {...getRootProps()}>
            <input {...getInputProps()} />

            <Grid item container>
              <Button
                variant="text"
                color="blue"
                compact
                disabled={dropzoneDisabled}
                startIcon={<FilePlus />}
                label={<FormattedMessage id="label.add_documents" />}
              />
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

export default ViewRequestPanelContentDocumentsHeader;
