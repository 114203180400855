import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (isPaid: boolean): Record<string, SxProps<Theme>> => ({
  container: {
    padding: 3,
    flexWrap: 'nowrap',
    border: `1px solid ${COLOURS.Coconut.Solid[200]}`,
    borderRadius: 1,
  },

  paidButton: {
    borderRadius: '16px !important',
    backgroundColor: isPaid ? COLOURS.Fig.Solid[100] : undefined,
    color: COLOURS.Fig.Solid[600],

    '&:hover': {
      backgroundColor: isPaid ? `${COLOURS.Fig.Solid[200]} !important` : undefined,
    },
  },
});
