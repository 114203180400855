import State from '@/app/types/state';
import { createSelector } from 'reselect';

export const getRequestFilters = (state: State) => state.backoffice.requests.filters;

export const getRequestFiltersApplied = createSelector([getRequestFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector([getRequestFilters], (filters) => ({
  filters,
}));
