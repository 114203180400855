import State from '@/app/types/state';
import { NoDataContainerSelector } from '@/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

export const getSingleWallet = (state: State) => state.backoffice.wallets.singleWallet;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getSingleWallet], (wallet) => ({
  loading: wallet.loading,
  loaded: wallet.loaded,
  hasData: wallet.hasData,
  error: wallet.error,
}));

export const getWalletDataAvailable = createSelector([getSingleWallet], (wallet) => {
  return !wallet.error && !wallet.loading && wallet.loaded;
});
