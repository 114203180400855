import Grid from '@mui/material/Grid';
import { useAppSelector } from 'hooks/useAppSelector';
import { AttachedDocumentsItem } from 'pages/backoffice/Requests/CreateEditRequestPanel/components/CreateEditRequestPanelForm/components';
import React from 'react';
import selector from './selector';

const AttachedDocumentsList = () => {
  const { files, filenames, loading } = useAppSelector(selector);

  return (
    <>
      {(files?.length > 0 || filenames?.length > 0) && (
        <Grid item container direction="column" gap={1}>
          {filenames?.map((filename, index) => (
            <AttachedDocumentsItem
              title={filename}
              key={`${filename + index}`}
              index={index}
              loading={loading}
              alreadyUploaded
            />
          ))}

          {files?.map((file, index) => (
            <AttachedDocumentsItem title={file.name} key={`${file.name + index}`} index={index} loading={loading} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default AttachedDocumentsList;
