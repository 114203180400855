import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { WalletOutputSchema } from '@/generated';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { CreateEditWalletModalData, DeleteWalletModalData } from '@/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  item: WalletOutputSchema;
}

const WalletsRowMoreButton: FC<IProps> = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(openBackofficeModal(new CreateEditWalletModalData(item, false)));
  };

  const handleDelete = () => {
    dispatch(openBackofficeModal(new DeleteWalletModalData(item.id, false)));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="label.edit" />,
      dataTestId: 'wallets-table-row-more-button-edit',
      icon: <NotePencil />,
      onClick: handleEdit,
      visible: true,
    },
    {
      title: <FormattedMessage id="label.delete" />,
      dataTestId: 'wallets-table-row-more-button-delete',
      textColor: COLOURS.Strawberry.Solid[600],
      icon: <Trash color={COLOURS.Strawberry.Solid[600]} />,
      onClick: handleDelete,
      visible: true,
    },
  ];

  return <MoreButton options={options} dataTestId="wallets-table-row-more-button" />;
};

export default WalletsRowMoreButton;
