import { NoDataContainerSelector } from 'components/NoDataContainer/NoDataContainer';
import { getViewPanelState } from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';

export const noDataSelector: NoDataContainerSelector = createSelector([getViewPanelState], (state) => ({
  loading: state?.loading,
  loaded: state?.loaded,
  hasData: state?.hasData,
  error: state?.error,
}));
