import State from '@/app/types/state';
import { toMutableArray } from '@/app/utils/app';
import { createSelector } from 'reselect';

export const getWalletsFilters = (state: State) => state.backoffice.wallets.filters;

export default createSelector([getWalletsFilters], (filters) => ({
  filters: {
    ...filters,
    types: toMutableArray(filters.types),
  },
}));
