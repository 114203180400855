import { ICreateEditRequestForm } from 'pages/backoffice/Requests/types';

export const SEARCH_FIELD_WIDTH = 280;
export const REQUEST_FILTERS_HEIGHT = 84;

export const SIDE_REQUEST_PANEL_TRANSITION_DURATION = 300;
export const SIDE_REQUEST_PANEL_DEFAULT_WIDTH = 700;
export const SIDE_REQUEST_PANEL_DEFAULT_HEIGHT = '100%';
export const SIDE_REQUEST_PANEL_ANIMATION_TIMEOUT = 500;
export const SIDE_REQUEST_PANEL_HEADER_HEIGHT = 84;

export const CREATE_EDIT_TEXT_FIELD_WIDTH = 500;
export const CREATE_EDIT_FOOTER_HEIGHT = 70;

export const VIEW_PANEL_HEADER_TITLE_MAX_WIDTH = 400;
export const VIEW_PANEL_HEADER_STATUS_MAX_WIDTH = 180;
export const VIEW_PANEL_HEADER_SKELETON_HEIGHT = 28;
export const VIEW_PANEL_FILE_ITEM_GRADIENT_ANGLE = -45;

export const DOCUMENT_ITEM_MIN_HEIGHT = 62;

export const EMPTY_REQUEST: ICreateEditRequestForm = {
  title: undefined,
  description: undefined,
};
