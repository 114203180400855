import { StatusV2Enum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage, IntlMessageKeys } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import { ProgressLine } from 'pages/backoffice/Requests/ViewRequestPanel/components/ViewRequestPanelContentStepper/components';
import React from 'react';
import selector from './selector';
import useStyles from './styles';

const ViewRequestPanelContentStepper = () => {
  const { status } = useAppSelector(selector);

  let stepTitle: IntlMessageKeys;
  let stepSubtitle: IntlMessageKeys;

  const styles = useStyles(status);

  switch (status) {
    case StatusV2Enum.BEING_DIGITIZED:
      stepTitle = 'stepper.title.step_1';
      stepSubtitle = 'stepper.subtitle.step_1';
      break;

    case StatusV2Enum.WAITING_FOR_APPROVAL:
      stepTitle = 'stepper.title.step_2';
      stepSubtitle = 'stepper.subtitle.step_2';
      break;

    case StatusV2Enum.APPROVED:
    case StatusV2Enum.PAID:
      stepTitle = 'stepper.title.step_3';
      stepSubtitle = 'stepper.subtitle.step_3';
      break;

    case StatusV2Enum.DECLINED:
      stepTitle = 'stepper.title.step_declined';
      stepSubtitle = 'stepper.subtitle.step_declined';
      break;

    default:
      return null;
  }

  return (
    <Grid item container sx={styles.container}>
      <ProgressLine status={status} />

      <Grid item container direction="column" gap={1} wrap="nowrap">
        <Grid item container justifyContent="space-between" wrap="nowrap">
          <Typography fontWeight="semiBold">
            <FormattedMessage id={stepTitle} />
          </Typography>

          {/* TODO: тут будет кнопка скрытия степпера */}
        </Grid>

        <Typography fontSize={12} color={COLOURS.Coconut.Solid[700]}>
          <FormattedMessage id={stepSubtitle} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelContentStepper;
