import { StatusV2Enum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (status?: StatusV2Enum): Record<string, SxProps<Theme>> => {
  let backgroundColor: string;

  switch (status) {
    case StatusV2Enum.BEING_DIGITIZED:
      backgroundColor = COLOURS.Fig.Alpha['500A8'];
      break;

    case StatusV2Enum.WAITING_FOR_APPROVAL:
      backgroundColor = COLOURS.Banana.Alpha['500A8'];
      break;

    case StatusV2Enum.APPROVED:
    case StatusV2Enum.PAID:
      backgroundColor = COLOURS.Salad.Alpha['500A8'];
      break;

    case StatusV2Enum.DECLINED:
      backgroundColor = COLOURS.Strawberry.Alpha['500A8'];
      break;

    default:
      backgroundColor = COLOURS.Coconut.Alpha['500A8'];
      break;
  }

  return {
    container: {
      backgroundColor,
      borderRadius: 1,
      padding: 3,
      gap: 3,
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  };
};
