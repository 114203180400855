import State from '@/app/types/state';
import { getUploadedByOptions } from '@/dictionary/selector';
import { createSelector } from 'reselect';

export const getSingleWalletFilters = (state: State) => state.backoffice.wallets.singleWalletFilters;

export default createSelector([getSingleWalletFilters, getUploadedByOptions], (filters, uploadedByOptions) => ({
  filters: {
    ...filters,

    uploadedBy: Array.isArray(filters.uploadedBy) ? filters.uploadedBy : [filters.uploadedBy],
    status: Array.isArray(filters.status) ? filters.status : [filters.status],
  },
  uploadedByOptions,
}));
