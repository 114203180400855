import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { REQUEST_FILTERS_HEIGHT } from 'pages/backoffice/Requests/consts';

export default (): Record<string, SxProps<Theme>> => ({
  noDataContainer: {
    paddingX: 6,
    height: `calc(100vh - ${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + REQUEST_FILTERS_HEIGHT}px)`,
    overflow: 'hidden',
  },
});
