import { myocean__schemas__files__FileSchema } from '@/generated';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { FileDashed, ImagesSquare } from '@phosphor-icons/react';
import IconButtonWithTooltip from 'components/IconButtonWithTooltip';
import { FormattedMessage } from 'components/Intl';
import isNil from 'lodash-es/isNil';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from 'pages/backoffice/Modals/command';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  file: myocean__schemas__files__FileSchema;
  buttonsDisabled: boolean;
}

const ViewRequestPanelContentDocumentsItemFile = (props: IProps) => {
  const { file, buttonsDisabled } = props;

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleOpenFilePreview = () => {
    const fileLinks = [file.link];

    // child_files существуют у файлов вроде PDF, которые могут содержать несколько изображений
    file.child_files?.forEach((file) => {
      if (!isNil(file.child_files)) {
        const childFilesLinks = file.child_files.map((childFile) => childFile.link);

        fileLinks.push(...childFilesLinks);
      }
    });

    dispatch(openBackofficeModal(new ImageCarouselModalData(fileLinks, file.document_id, file.status)));
  };

  return (
    <Grid item container sx={styles.container}>
      <Grid item container gap={3} width="auto" wrap="nowrap">
        <Grid item>
          <SvgIcon fontSize="large">
            <FileDashed />
          </SvgIcon>
        </Grid>

        <Grid item container direction="column" gap={2} wrap="nowrap">
          <Typography fontWeight="semiBold">{file.filename}</Typography>

          <Typography fontSize={12}>
            <FormattedMessage id="label.being_digitized" />
          </Typography>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" width="auto">
        <IconButtonWithTooltip
          onClick={handleOpenFilePreview}
          icon={<ImagesSquare />}
          disabled={buttonsDisabled}
          tooltipLabel="label.open_source_file"
          ariaLabel="aria.label.open_file_preview"
        />
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelContentDocumentsItemFile;
