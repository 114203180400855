import { StatusV2Enum } from '@/generated';
import { COLOURS } from '@core-ui/styles';
import { IBadgeColor } from 'app/types/common';
import { exhaustiveGuard } from 'app/utils/app';

export const getBadgeColors = (status?: StatusV2Enum): IBadgeColor | undefined => {
  switch (status) {
    case StatusV2Enum.BEING_DIGITIZED:
      return {
        backgroundColor: COLOURS.Grape.Solid[200],
        textColor: COLOURS.Grape.Solid[800],
      };

    case StatusV2Enum.WAITING_FOR_APPROVAL:
      return {
        backgroundColor: COLOURS.Orange.Solid[200],
        textColor: COLOURS.Orange.Solid[800],
      };

    case StatusV2Enum.APPROVED:
      return {
        backgroundColor: COLOURS.Salad.Solid[200],
        textColor: COLOURS.Salad.Solid[800],
      };

    case StatusV2Enum.PAID:
      return {
        backgroundColor: COLOURS.Cucumber.Solid[200],
        textColor: COLOURS.Cucumber.Solid[800],
      };

    case StatusV2Enum.DECLINED:
      return {
        backgroundColor: COLOURS.Strawberry.Solid[200],
        textColor: COLOURS.Strawberry.Solid[800],
      };

    case undefined:
      return undefined;
    default:
      return exhaustiveGuard(status);
  }
};
