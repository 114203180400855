import { useAppSelector } from '@/hooks/useAppSelector';
import Button from '@core-ui/button';
import { useFilter } from '@core-ui/hooks';
import { useQuery } from '@core-ui/redux-router';
import TextField from '@core-ui/text_field';
import { updateQuery } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import { MagnifyingGlass, Plus } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import { REQUEST_FILTERS_HEIGHT, SEARCH_FIELD_WIDTH } from 'pages/backoffice/Requests/consts';
import { IRequestsFiltersState } from 'pages/backoffice/Requests/RequestsFilters/reducer';
import { getRequestList } from 'pages/backoffice/Requests/RequestsList/actions';
import { IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setTextFilter } from './actions';
import selector from './selector';

const RequestsFilter = () => {
  const { filters } = useAppSelector(selector);

  const query = useQuery<IRequestsQueryParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFilterChange = useFilter<IRequestsFiltersState>({
    getList: getRequestList,
    setFilter: setTextFilter,
  });

  const handleOpenCreateEditRequest = () => {
    history.replace(updateQuery<IRequestsQueryParams>({ panelMode: 'create' }));
  };

  const singleRequestPanelClosed = query.panelMode === undefined;

  return (
    <Grid container wrap="nowrap" padding={6} justifyContent="space-between" height={REQUEST_FILTERS_HEIGHT}>
      <Grid item width={SEARCH_FIELD_WIDTH}>
        <TextField
          fullWidth
          size="small"
          value={filters.name}
          label={<FormattedMessage id="filters.search_by_request_name" />}
          // TODO: тип параметра onChange в компоненте TextField не соответствует handleFilterChange
          // @ts-ignore
          onChange={handleFilterChange('name')}
          rightIcon1={
            <SvgIcon>
              <MagnifyingGlass />
            </SvgIcon>
          }
        />
      </Grid>

      <Button
        variant="contained"
        color="blue"
        size="small"
        label={<FormattedMessage id="label.add_request" />}
        onClick={handleOpenCreateEditRequest}
        disabled={!singleRequestPanelClosed}
        startIcon={<Plus />}
      />
    </Grid>
  );
};

export default RequestsFilter;
