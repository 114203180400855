import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: 6,
    position: 'sticky',
    top: 0,
    backgroundColor: COLOURS.Coconut.Solid[100],
    zIndex: 2,
  },
});
