import State from 'app/types/state';
import { createSelector } from 'reselect';

export const getViewPanelState = (state: State) => state.backoffice.requests.viewRequestPanel;
export const getViewPanelStateValue = (state: State) => state.backoffice.requests.viewRequestPanel.value;
export const getViewPanelLoading = (state: State) => state.backoffice.requests.viewRequestPanel.loading;
export const getViewPanelDisabled = (state: State) => state.backoffice.requests.viewRequestPanel.disabled;

export const getRequestId = (state: State) => state.backoffice.requests.viewRequestPanel.value?.id;

export default createSelector([getViewPanelStateValue], (value) => ({
  storedRequest: value,
  storedRequestId: value?.id,
}));
