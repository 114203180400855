import { SxProps, Theme } from '@mui/material/styles';
import { CREATE_EDIT_FOOTER_HEIGHT } from 'pages/backoffice/Requests/consts';

export default (): Record<string, SxProps<Theme>> => ({
  footerContainer: {
    position: 'absolute',
    width: '700px',
    bottom: 0,
    right: 0,
    height: CREATE_EDIT_FOOTER_HEIGHT,
    gap: 4,
    direction: 'column',
    marginTop: 'auto',
  },

  divider: {
    width: '100%',
  },

  buttonContainer: {
    justifyContent: 'flex-end',
    padding: 4,
    paddingTop: 0,
  },
});
