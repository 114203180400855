import { DELETE, PATCH } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { exhaustiveGuard } from '@/app/utils/app';
import { showNotification } from '@/components/Notification/actions';
import { DocPatchInputSchema, FileStatusEnum } from '@/generated';
import { closeBackofficeModal, toggleModalLoading } from '@/pages/backoffice/Modals/actions';
import { getSingleWalletList, setSingleWalletListLoading } from '@/pages/backoffice/Wallets/SingleWalletList/actions';
import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* updateErpCategory({ payload }: Action<actions.IUpdateErpCategory>) {
  try {
    yield put(setSingleWalletListLoading({ loading: true }));

    yield call(PATCH, `/document/${payload.documentId}`, {
      xero_category: payload.categoryId,
    } as DocPatchInputSchema);

    yield put(getSingleWalletList(null));
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success.text.erp_update',
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        variant: 'error',
        titleId: 'notification.error.text.erp_update',
      })
    );
  } finally {
    yield put(setSingleWalletListLoading({ loading: false }));
  }
}

function* deleteWalletTransaction({ payload }: Action<actions.IDeleteWalletTransaction>) {
  try {
    yield put(toggleModalLoading());

    // TODO: we are not sure about UNKNOWN status, need to check which endpoint should be used for this type of file
    switch (payload.status) {
      case FileStatusEnum.DIGITIZED:
      case FileStatusEnum.UNKNOWN:
        yield call(DELETE, `/document/${payload.id}`);
        break;
      case FileStatusEnum.RETURNED:
      case FileStatusEnum.BEING_DIGITIZED:
        yield call(DELETE, `/files/${payload.id}`);
        break;
      default:
        exhaustiveGuard(payload.status);
    }

    yield all([
      put(getSingleWalletList(null)),
      put(closeBackofficeModal()),
      put(
        showNotification({
          variant: 'success',
          titleId: 'notification.success.text.wallet_transaction_delete',
        })
      ),
    ]);
  } catch (e) {
    yield all([call(responseError, e), put(toggleModalLoading())]);
  }
}

export default [
  takeLatest(actions.updateErpCategory, updateErpCategory),
  takeLatest(actions.deleteWalletTransaction, deleteWalletTransaction),
];
