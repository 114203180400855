import { type StatusV2Enum } from '@/generated';
import Typography from '@mui/material/Typography';
import { getBadgeColors } from 'pages/backoffice/Requests/components/RequestStatusBadge/utils';
import React from 'react';
import useStyles from './styles';

interface IProps {
  status?: StatusV2Enum;
}

const RequestStatusBadge = (props: IProps) => {
  const { status } = props;

  const badgeColor = getBadgeColors(status);
  const styles = useStyles(badgeColor);

  const formattedStatus = status?.replace(/_/g, ' ');

  return (
    <Typography component="div" sx={styles.container}>
      {formattedStatus}
    </Typography>
  );
};

export default RequestStatusBadge;
