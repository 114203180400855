import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { CREATE_EDIT_FOOTER_HEIGHT, SIDE_REQUEST_PANEL_HEADER_HEIGHT } from 'pages/backoffice/Requests/consts';

export default (): Record<string, SxProps<Theme>> => ({
  form: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: 4,
    paddingTop: 4,
    paddingX: 5,
    height: `calc(100vh - (${
      HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + SIDE_REQUEST_PANEL_HEADER_HEIGHT + CREATE_EDIT_FOOTER_HEIGHT
    }px))`,
    overflow: 'auto',
  },
});
