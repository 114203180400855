import { getCostArticleMappingEnabled } from 'app/selectors';
import { getAmountFormatter, getCurrencyCodeById } from 'dictionary/selector';
import { getRequestList } from 'pages/backoffice/Requests/RequestsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getRequestList, getCostArticleMappingEnabled, getAmountFormatter, getCurrencyCodeById],
  (requestList, costArticleMappingEnabled, amountFormatter, currencyCodeGetter) => ({
    items: requestList.value,
    costArticleMappingEnabled: Boolean(costArticleMappingEnabled),
    amountFormatter,
    currencyCodeGetter,
  })
);
