import { asMutable } from '@core-ui/immutable';
import { NoDataContainerSelector } from 'components/NoDataContainer/NoDataContainer';
import {
  getCreateEditFiles,
  getCreateEditRequestInitialValue,
  getCreateEditRequestState,
  getCreateEditRequestValue,
} from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import { createSelector } from 'reselect';

export const noDataSelector: NoDataContainerSelector = createSelector([getCreateEditRequestState], (state) => ({
  loading: state?.loading,
  loaded: state?.loaded,
  hasData: state?.hasData,
  error: state?.error,
}));

export default createSelector(
  [getCreateEditRequestValue, getCreateEditRequestInitialValue, getCreateEditFiles],
  (storeValue, initialValue, files) => ({
    // we use asMutable here to prevent app crash when mutating form state via mutators when adding/removing sections and lines
    storeValue: storeValue ? asMutable(storeValue, { deep: true }) : undefined,
    initialValue,
    files,
  })
);
