import Tooltip from '@mui/material/Tooltip';
import { OrderWalletsFields } from '@/generated';
import { ITableColumn } from '@/pages/backoffice/types';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

export const HEADERS: ITableColumn[] = [
  {
    key: 'type',
    title: <FormattedMessage id="table.header.type" />,
    width: 50,
  },
  {
    key: 'wallet_name',
    title: <FormattedMessage id="table.header.wallet_name" />,
    orderBy: OrderWalletsFields.NAME,
    dataTestId: 'table-column-head-wallet-name',
    width: 940,
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.total_expenses" />,
    orderBy: OrderWalletsFields.AMOUNT,
    dataTestId: 'table-column-head-expenses',
    width: 140,
    align: 'right',
  },
  {
    key: 'actions',
    title: '',
    width: 130,
  },
];
