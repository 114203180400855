import { DocTypeEnum, type DocumentBaseV2Schema } from '@/generated';
import State from 'app/types/state';
import { exhaustiveGuard } from 'app/utils/app';
import { getAmountFormatter, getCurrencyCodeById } from 'dictionary/selector';
import { INVOICE_FILE_UPLOAD_TYPE_ENUM } from 'pages/backoffice/Requests/types';
import {
  getViewPanelDisabled,
  getViewPanelLoading,
  getViewPanelStateValue,
} from 'pages/backoffice/Requests/ViewRequestPanel/selector';
import { createSelector } from 'reselect';

const getDocuments = (state: State) => state.backoffice.requests.viewRequestPanel.value?.documents;

// document - оцифрованный документ
const getFilteredRequestDocuments = createSelector(
  [getDocuments],
  (documents) =>
    documents?.reduce<DocumentBaseV2Schema[]>((acc, item) => {
      // TODO: т.к. сейчас нет квотного флоу, то квоты не показываем. Когда этот флоу будет проработан фильтрацию изменим или вовсе уберём
      switch (item.document_type) {
        case DocTypeEnum.POST_PAYMENT_INVOICE:
        case DocTypeEnum.PRE_PAYMENT_INVOICE:
        case DocTypeEnum.RECEIPT:
          acc.push(item);
          break;
        case DocTypeEnum.QUOTE:
        case DocTypeEnum.DEFAULT:
        case undefined:
          break;
        default:
          return exhaustiveGuard(item.document_type);
      }

      return acc;
    }, []) ?? []
);

const getFiles = (state: State) => state.backoffice.requests.viewRequestPanel.value?.files_tree;

// file - не оцифрованный документ
export const getFilteredFiles = createSelector([getFiles], (files) => {
  return (
    files?.filter(
      (file) =>
        // TODO: сейчас файлы могут иметь только типы prepayment и postpayment, но это может измениться когда будем добавлять квотный флоу
        (file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.POSTPAYMENT ||
          file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT) &&
        !file.is_digitized
    ) ?? []
  );
});

export default createSelector(
  [
    getViewPanelLoading,
    getViewPanelDisabled,
    getViewPanelStateValue,
    getFilteredRequestDocuments,
    getFilteredFiles,
    getCurrencyCodeById,
    getAmountFormatter,
  ],
  (loading, disabled, request, documents, files, getCurrencyCode, amountFormatter) => ({
    buttonsDisabled: loading || disabled,
    requestId: request?.id,
    documents,
    files,
    getCurrencyCode,
    amountFormatter,
  })
);
