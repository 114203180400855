import singleWalletList, { ISingleWalletListState } from '@/pages/backoffice/Wallets/SingleWalletList/reducer';
import singleWalletDragNDrop, {
  ISingleWalletDragNDropState,
} from '@/pages/backoffice/Wallets/SingleWalletDragNDrop/reducer';
import singleWalletFilters, { ISingleWalletFiltersState } from '@/pages/backoffice/Wallets/SingleWalletFilters/reducer';
import singleWallet, { ISingleWalletState } from '@/pages/backoffice/Wallets/SingleWalletRoot/reducer';
import filters, { IWalletsFiltersState } from '@/pages/backoffice/Wallets/WalletsFilters/reducer';
import list, { IWalletsListState } from '@/pages/backoffice/Wallets/WalletsList/reducer';
import { combineReducers } from 'redux';

export interface IWalletsState {
  list: IWalletsListState;
  filters: IWalletsFiltersState;
  singleWallet: ISingleWalletState;
  singleWalletList: ISingleWalletListState;
  singleWalletFilters: ISingleWalletFiltersState;
  singleWalletDragNDrop: ISingleWalletDragNDropState;
}

export default combineReducers({
  list,
  filters,
  singleWallet,
  singleWalletList,
  singleWalletFilters,
  singleWalletDragNDrop,
});
