import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CreateEditRequestPanel from 'pages/backoffice/Requests/CreateEditRequestPanel';
import RequestsFilter from 'pages/backoffice/Requests/RequestsFilters';
import RequestsList from 'pages/backoffice/Requests/RequestsList';
import ViewRequestPanel from 'pages/backoffice/Requests/ViewRequestPanel';
import React from 'react';
import useStyles from './styles';

const RequestsRoot = () => {
  const styles = useStyles();

  return (
    <Container maxWidth="xl" sx={styles.container}>
      <Grid item container direction="column" wrap="nowrap" overflow="hidden">
        <RequestsFilter />
        <RequestsList />
      </Grid>

      <ViewRequestPanel />
      <CreateEditRequestPanel />
    </Container>
  );
};

export default RequestsRoot;
