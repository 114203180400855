import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: 5,
    paddingY: 6,
    borderBottom: `1px solid ${COLOURS.Coconut.Solid[200]}`,
  },
});
