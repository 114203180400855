import { useQuery } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import NoDataContainer from 'components/NoDataContainer';
import { FormApi } from 'final-form';
import { useAppSelector } from 'hooks/useAppSelector';
import { ICreateEditRequestForm, IRequestsQueryParams } from 'pages/backoffice/Requests/types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { AttachedDocuments, Footer, InformationFields } from './components';
import selector, { noDataSelector } from './selector';
import useStyles from './styles';
import handleSubmitForm from './submit';
import validate from './validate';

export let PANEL_FORM: FormApi<ICreateEditRequestForm, Partial<ICreateEditRequestForm>> | undefined;

const CreateEditRequestPanelForm = () => {
  const { storeValue, initialValue, files } = useAppSelector(selector);

  const dispatch = useDispatch();
  const styles = useStyles();
  const query = useQuery<IRequestsQueryParams>();

  return (
    <NoDataContainer selector={noDataSelector}>
      <Form<ICreateEditRequestForm>
        subscription={{}}
        initialValues={storeValue}
        validate={validate}
        onSubmit={handleSubmitForm(initialValue, dispatch, files, query.selectedRequestId)}
      >
        {({ form, handleSubmit }) => {
          PANEL_FORM = form;

          return (
            <Grid item container component="form" sx={styles.form} onSubmit={handleSubmit}>
              <InformationFields />
              <AttachedDocuments />
              <Footer />
            </Grid>
          );
        }}
      </Form>
    </NoDataContainer>
  );
};

export default CreateEditRequestPanelForm;
