import reducer, { Data } from '@core-ui/reducers_handlers';
import { WalletOutputSchema } from '@/generated';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ISingleWalletState extends Data<WalletOutputSchema> {}

const defaultState = Immutable<ISingleWalletState>({
  loading: false,
  loaded: false,
  hasData: false,
});

export default handleActions<typeof defaultState, unknown>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
