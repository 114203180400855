import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: 2,
    paddingX: 5,
    paddingY: 6,
    borderBottom: `1px solid ${COLOURS.Coconut.Solid[200]}`,
  },

  titleContainer: {
    overflow: 'hidden',
  },

  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
