import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { SIDE_REQUEST_PANEL_HEADER_HEIGHT } from 'pages/backoffice/Requests/consts';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: 8,
    padding: 5,
    height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight + SIDE_REQUEST_PANEL_HEADER_HEIGHT}px))`,
    overflow: 'auto',
  },
});
