import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '@/app/consts/app';
import { AmountFormatter, CurrencyCodeGetterById } from '@/app/types/common';
import { formatTime } from '@/app/utils/dates';
import StatusBadge from '@/components/StatusBadge';
import { FileStatusEnum, WalletRecordSchema } from '@/generated';
import SingleWalletRowMoreButton from '@/pages/backoffice/Wallets/SingleWalletList/SingleWalletRowMoreButton';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { openBackofficeModal } from 'pages/backoffice/Modals/actions';
import { MasterDocumentModalData } from 'pages/backoffice/Modals/command';

interface IProps {
  item: WalletRecordSchema;
  boatId: Nullable<number>;
  walletId: number;
  amountFormatter: AmountFormatter;
  currencyCodeGetter: CurrencyCodeGetterById;
}

const SingleWalletRow: FC<IProps> = (props) => {
  const { item, boatId, walletId, amountFormatter, currencyCodeGetter } = props;

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleOpenItem = () => {
    if (isDocumentDigitized && item.document_id) {
      dispatch(openBackofficeModal(new MasterDocumentModalData({ documentId: item.document_id })));
    }
  };

  const createdAt = formatTime(item.created_at, { dateFormat: DATE_FORMAT });

  const uploadedByName = [item.uploaded_by_first_name, item.uploaded_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  const isDocumentDigitized = item.status === FileStatusEnum.DIGITIZED;

  const reasonOfReturn =
    item.status === FileStatusEnum.RETURNED && item.reason_of_return
      ? `${item.status}: ${item.reason_of_return}`.replace(/_/g, ' ')
      : `${item.status}`.replace(/_/g, ' ');

  const currency = item.currency_id ? currencyCodeGetter(Number(item.currency_id)) : '';

  return (
    <TableRow>
      <TableCell onClick={handleOpenItem} sx={styles.nameCell}>
        {item.filename}
      </TableCell>

      <TableCell sx={styles.breakWordCell}>{item.vendor}</TableCell>

      <TableCell>{createdAt}</TableCell>

      <TableCell>
        <StatusBadge status={item.status} label={reasonOfReturn} />
      </TableCell>

      <TableCell sx={styles.breakWordCell}>{uploadedByName}</TableCell>

      <TableCell>
        <Typography fontFamily="mono">{currency}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>

      <TableCell padding="medium">
        <Grid container gap={2} justifyContent="flex-start" alignItems="center">
          <SingleWalletRowMoreButton item={item} />

          {isDocumentDigitized && <IconButtonWithTooltip onClick={handleOpenItem} tooltipLabel="label.open_document" />}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default SingleWalletRow;
