import { createAction } from 'redux-actions';

export const reducerName = 'wallets.single_wallet.drag_n_drop';

export interface IUploadDocument {
  files: File[];
}

export const uploadDocument = createAction<IUploadDocument>(`${reducerName}.UPLOAD_DOCUMENT`);

export const setLoading = createAction<boolean>(`${reducerName}.SET_LOADING`);

export const setPlaceholders = createAction<string[]>(`${reducerName}.SET_PLACEHOLDERS`);
