import { HeaderCell, TableRow } from '@core-ui/table';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'hooks/useAppSelector';
import { getRequestList, setOrder, setOrderBy } from 'pages/backoffice/Requests/RequestsList/actions';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import { getHeaders } from './utils';
import selector from './selector';

const RequestsListTableHeader = () => {
  const { costArticleMappingEnabled, orderBy, order } = useAppSelector(selector);

  const dispatch = useDispatch();
  const headers = getHeaders(costArticleMappingEnabled);

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setOrder(SORT_ORDER_ENUM.DESC));
      } else {
        dispatch(setOrder(order === SORT_ORDER_ENUM.ASC ? SORT_ORDER_ENUM.DESC : SORT_ORDER_ENUM.ASC));
      }

      dispatch(setOrderBy(value));
      dispatch(getRequestList(null));
    });
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            isActive={orderBy === column.orderBy}
            onChangeSort={column.orderBy ? handleChangeSort(column.orderBy) : undefined}
            order={order}
            sx={{
              cursor: column.orderBy ? 'pointer' : 'initial',
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default RequestsListTableHeader;
