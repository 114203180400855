import { CalculatedRequestStatusEnum, type myocean__schemas__files__FileSchema, StatusV2Enum } from '@/generated';
import { exhaustiveGuard } from 'app/utils/app';
import isNil from 'lodash-es/isNil';
import { INVOICE_FILE_UPLOAD_TYPE_ENUM } from 'pages/backoffice/Requests/types';

// TODO: удалить эту функцию только после полного завершения переезда на новый бэк раздела Requests
export const mapLegacyStatusToNewStatus = (legacyStatus?: CalculatedRequestStatusEnum): StatusV2Enum => {
  switch (legacyStatus) {
    case CalculatedRequestStatusEnum.DRAFT_REQUEST:
    case CalculatedRequestStatusEnum.QUOTE_S_BEING_DIGITIZED:
    case CalculatedRequestStatusEnum.INVOICE_S_BEING_DIGITIZED:
      return StatusV2Enum.BEING_DIGITIZED;

    case CalculatedRequestStatusEnum.REQUEST_AWAITING_APPROVAL:
    case CalculatedRequestStatusEnum.COLLECTING_QUOTES:
    case CalculatedRequestStatusEnum.QUOTE_AWAITING_APPROVAL:
    case CalculatedRequestStatusEnum.AWAITING_INVOICE:
    case CalculatedRequestStatusEnum.INVOICE_AWAITING_APPROVAL:
    case CalculatedRequestStatusEnum.AWAITING_PAYMENT:
    case CalculatedRequestStatusEnum.PAYMENT_PENDING_APPROVAL:
    case CalculatedRequestStatusEnum.QUOTE_HAS_ERROR:
    case CalculatedRequestStatusEnum.INVOICE_HAS_ERROR:
    case CalculatedRequestStatusEnum.UNKNOWN:
      return StatusV2Enum.WAITING_FOR_APPROVAL;

    case CalculatedRequestStatusEnum.REQUEST_APPROVED:
    case CalculatedRequestStatusEnum.QUOTE_APPROVED:
      return StatusV2Enum.APPROVED;

    case CalculatedRequestStatusEnum.PAID:
    case CalculatedRequestStatusEnum.DONE:
    case CalculatedRequestStatusEnum.PAID_DELIVERED:
    case CalculatedRequestStatusEnum.DELIVERED:
      return StatusV2Enum.PAID;

    case CalculatedRequestStatusEnum.REQUEST_DECLINED:
    case CalculatedRequestStatusEnum.QUOTE_DECLINED:
    case CalculatedRequestStatusEnum.INVOICE_DECLINED:
      return StatusV2Enum.DECLINED;

    case undefined:
      return StatusV2Enum.BEING_DIGITIZED;
    default:
      return exhaustiveGuard(legacyStatus);
  }
};

export const getFilenamesFromFilesTree = (filesTree?: myocean__schemas__files__FileSchema[]): string[] => {
  return (
    filesTree?.reduce<string[]>((acc, file) => {
      if (
        file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.POSTPAYMENT ||
        file.doc_type === INVOICE_FILE_UPLOAD_TYPE_ENUM.PREPAYMENT
      ) {
        acc.push(file.filename);

        // child_files существуют у файлов вроде PDF, которые могут содержать несколько изображений
        if (!isNil(file.child_files)) {
          const childFilesNames = file.child_files.map((childFile) => childFile.filename);

          acc.push(...childFilesNames);
        }
      }

      return acc;
    }, []) ?? []
  );
};
