import { PUT } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { showNotification } from '@/components/Notification/actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getRequest } from '../QuotesList/actions';
import * as actions from './actions';
import { getRequestId } from './selector';

function* updateRequestStatus() {
  try {
    const requestId: string = yield select(getRequestId);

    yield call(PUT, `/requests/done?request_id=${requestId}`);
    yield put(
      showNotification({
        variant: 'success',
        titleId: 'notification.success',
      })
    );

    yield put(getRequest({ requestId }));
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [takeLatest(actions.updateRequestStatus, updateRequestStatus)];
