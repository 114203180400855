import { HEIGHT_SIZE } from '@core-ui/styles';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/material/styles';
import WalletsFilters from '@/pages/backoffice/Wallets/WalletsFilters';
import WalletsList from '@/pages/backoffice/Wallets/WalletsList';
import React from 'react';

const containerStyle: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
  overflow: 'hidden',
};

const WalletsRoot = () => {
  return (
    <Container maxWidth="xl" sx={containerStyle}>
      <WalletsFilters />
      <WalletsList />
    </Container>
  );
};

export default WalletsRoot;
