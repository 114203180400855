import Grid from '@mui/material/Grid';
import { TextFieldForm } from 'components/Form';
import { FormattedMessage } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import { CREATE_EDIT_TEXT_FIELD_WIDTH } from 'pages/backoffice/Requests/consts';
import { getCreateEditRequestUiDisabled } from 'pages/backoffice/Requests/CreateEditRequestPanel/selector';
import React from 'react';
import { Field } from 'react-final-form';

const InformationFields = () => {
  const { disabled } = useAppSelector(getCreateEditRequestUiDisabled);

  return (
    <>
      <Grid item width={CREATE_EDIT_TEXT_FIELD_WIDTH}>
        <Field
          name="title"
          label={<FormattedMessage id="label.request_name" />}
          component={TextFieldForm}
          fullWidth
          disabled={disabled}
        />
      </Grid>

      <Grid item width={CREATE_EDIT_TEXT_FIELD_WIDTH}>
        <Field
          name="description"
          label={<FormattedMessage id="label.description" />}
          component={TextFieldForm}
          multiline
          minRows={2}
          fullWidth
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default InformationFields;
