import State from '@/app/types/state';
import { FileTypeEnum } from '@/generated';

// TODO: эта утилита должна была использоваться для указания типа файла при его загрузке на бэк,
// но оказалось, что сейчас ручка не способна принимать несколько типов, а несколько файлов
// разного типа способна, отложили на "после релиза"
export const getFileType = (file: File): string => {
  const fileType = file.name.split('.').pop();

  // TODO: добавить бы типизацию типу файла и exhaustiveGuard() этому свичу
  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return FileTypeEnum.IMAGE;
    case 'pdf':
      return FileTypeEnum.PDF;
    case 'zip':
      return FileTypeEnum.ZIP;
    case 'tiff':
      return FileTypeEnum.TIFF;
    default:
      return FileTypeEnum.FILE;
  }
};

export default (state: State) => state.backoffice.wallets.singleWalletDragNDrop.isLoading;
