import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import BeingDigitizedItemTools from '@/pages/backoffice/Requests/SingleRequest/components/beingDigitizedItemTools';
import ItemTools from '@/pages/backoffice/Requests/SingleRequest/components/ItemTools';
import { IInvoice, IPaymentRequestFile, IQuote } from '@/pages/backoffice/Requests/types';
import { FC } from 'react';

interface IProps {
  expanded: boolean;
  document: IQuote | IInvoice | IPaymentRequestFile;
}

const ItemExpanded: FC<IProps> = (props) => {
  const { expanded, document } = props;

  const isDocumentAlreadyDigitized = 'files' in document;

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
      <Grid container paddingLeft="42px" paddingRight={4}>
        {isDocumentAlreadyDigitized ? (
          <ItemTools document={document} />
        ) : (
          <BeingDigitizedItemTools document={document} />
        )}
      </Grid>
    </Collapse>
  );
};

export default ItemExpanded;
