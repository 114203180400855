import contracts, { IContractsState } from '@/pages/backoffice/Contracts/reducer';
import inbox, { IInboxState } from '@/pages/backoffice/Inbox/reducer';
import ledger, { ILedgerState } from '@/pages/backoffice/Ledger/reducer';
import modals, { IModalsState } from '@/pages/backoffice/Modals/reducer';
import requests, { IRequestsState } from '@/pages/backoffice/Requests/reducer';
import wallets, { IWalletsState } from '@/pages/backoffice/Wallets/reducer';
import masterDocumentModal, { IMasterDocumentModalState } from 'pages/backoffice/Modals/MasterDocumentModal//reducer';
import { combineReducers } from 'redux';

export interface IBackofficeState {
  modals: IModalsState;
  inbox: IInboxState;
  wallets: IWalletsState;
  contracts: IContractsState;
  requests: IRequestsState;
  ledger: ILedgerState;
  masterDocumentModal: IMasterDocumentModalState;
}

export default combineReducers({
  modals,
  inbox,
  wallets,
  contracts,
  requests,
  ledger,
  masterDocumentModal,
});
