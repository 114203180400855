import { all } from 'redux-saga/effects';
import contractsSagas from './Contracts/sagas';
import inboxSagas from './Inbox/sagas';
import ledgerSagas from './Ledger/sagas';
import modalsSagas from './Modals/sagas';
import requestSagas from './Requests/sagas';
import walletsSagas from './Wallets/sagas';
import masterDocumentModalSagas from 'pages/backoffice/Modals/MasterDocumentModal/sagas';

export default function* root() {
  yield all([
    ...inboxSagas,
    ...walletsSagas,
    ...contractsSagas,
    ...requestSagas,
    ...ledgerSagas,
    ...modalsSagas,
    ...masterDocumentModalSagas,
  ]);
}
