import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import { BACKEND_URL } from '@/app/consts/env';
import { IRouteParams } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { IQuote, QUOTE_STATUS_ENUM } from '@/pages/backoffice/Requests/types';
import { CheckCircle, DownloadSimple, X } from '@phosphor-icons/react';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import UseQuoteAsInvoiceModalData from '../../../../../Modals/command/UseQuoteAsInvoiceModalData';
import { updateQuoteStatus } from '../../actions';
import selector from './selector';

interface IProps {
  document: IQuote;
}

const QuoteButtons: FC<IProps> = (props) => {
  const { document } = props;

  const dispatch = useDispatch();
  const { requestId } = useParams<IRouteParams>();
  const { isLoading, requestIsArchive } = useAppSelector((state) => selector(state, { requestId }));

  const quoteStatus = document.document_approve_status;
  const quoteId = document.id;

  const handleApproveClick = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.APPROVED,
      })
    );
  };

  const handleDeclineClick = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.DECLINED,
      })
    );
  };

  const handleCancelClick = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.AWAITING,
      })
    );
  };

  const handleUseAsInvoiceClick = () => {
    dispatch(openBackofficeModal(new UseQuoteAsInvoiceModalData(requestId, quoteId)));
  };

  const handleDownloadClick = () => {
    window.open(`${BACKEND_URL}/document/${quoteId}/csv`);
  };

  return (
    <Grid item container wrap="nowrap" gap={1} padding="16px 16px 16px 42px">
      <Grid item>
        <Button
          sx={{ whiteSpace: 'nowrap' }}
          color="blue"
          variant="outlined"
          startIcon={<DownloadSimple />}
          label={<FormattedMessage id="label.download_csv" />}
          onClick={handleDownloadClick}
        />
      </Grid>

      <Grid item container wrap="nowrap" justifyContent="flex-end" gap={1}>
        {quoteStatus === QUOTE_STATUS_ENUM.AWAITING && (
          <>
            <Button
              disabled={isLoading}
              color="blue"
              variant="contained"
              startIcon={<CheckCircle />}
              label={<FormattedMessage id="label.approve" />}
              onClick={handleApproveClick}
            />

            <Button
              disabled={isLoading}
              variant="outlined"
              startIcon={<X />}
              label={<FormattedMessage id="label.decline" />}
              onClick={handleDeclineClick}
            />
          </>
        )}

        {(quoteStatus === QUOTE_STATUS_ENUM.APPROVED ||
          (quoteStatus === QUOTE_STATUS_ENUM.DECLINED && !requestIsArchive)) && (
          <Button
            disabled={isLoading}
            variant="outlined"
            label={
              <FormattedMessage
                id={
                  quoteStatus === QUOTE_STATUS_ENUM.APPROVED
                    ? 'request_detail.buttons.cancel_approve'
                    : 'request_detail.buttons.cancel_decline'
                }
              />
            }
            onClick={handleCancelClick}
          />
        )}

        <Button
          disabled={isLoading}
          variant="outlined"
          label={<FormattedMessage id="request_detail.buttons.use_as_invoice" />}
          onClick={handleUseAsInvoiceClick}
        />
      </Grid>
    </Grid>
  );
};

export default QuoteButtons;
