import { RequestMinimalOutputV2Schema } from '@/generated';
import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import { createAction } from 'redux-actions';

export const reducerName = 'requests.list';

export const resetRequestsList = resetState(reducerName);

export const getRequestList = getList(reducerName);
export const setRequestList = setList<RequestMinimalOutputV2Schema>(reducerName);

export const getChunkRequestList = getChunkList(reducerName);
export const setChunkRequestList = setChunkList<RequestMinimalOutputV2Schema>(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setOrder = createAction<SORT_ORDER_ENUM>(`${reducerName}.SET_ORDER`);

export const setRequestListLoading = setLoading(reducerName);
export const setRequestListPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export interface IAddItemToRequestListPayload {
  item: RequestMinimalOutputV2Schema;
}

export const addItemToRequestList = createAction<IAddItemToRequestListPayload>(`${reducerName}.ADD_ITEM_TO_LIST`);

export interface IUpdateItemInRequestListPayload {
  item: RequestMinimalOutputV2Schema;
}

export const updateItemInRequestList = createAction<IUpdateItemInRequestListPayload>(
  `${reducerName}.UPDATE_ITEM_IN_REQUEST_LIST`
);
