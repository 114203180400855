import Tooltip from '@mui/material/Tooltip';
import { myocean__routes__wallets__wallet__OrderFields } from '@/generated';
import { ITableColumn } from '@/pages/backoffice/types';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

// TODO: добавить столбец для erp upload pending - ещё нет на бэке
// TODO: добавить столбец тегов когда будем их завозить
export const HEADERS: ITableColumn[] = [
  {
    key: 'transaction_name',
    title: <FormattedMessage id="table.header.document_name" />,
    orderBy: myocean__routes__wallets__wallet__OrderFields.DOC_NAME,
    dataTestId: 'table-column-head-document-name',
    width: 300,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    orderBy: myocean__routes__wallets__wallet__OrderFields.VENDOR_NAME,
    dataTestId: 'table-column-head-vendor-name',
    width: 200,
  },
  {
    key: 'upload_date',
    title: <FormattedMessage id="table.header.upload_date" />,
    orderBy: myocean__routes__wallets__wallet__OrderFields.CREATED_AT,
    dataTestId: 'table-column-head-created-at',
    width: 130,
  },
  {
    key: 'document_status',
    title: <FormattedMessage id="table.header.doc_status" />,
    orderBy: myocean__routes__wallets__wallet__OrderFields.DOC_STATUS,
    dataTestId: 'table-column-head-status',
    width: 190,
  },
  {
    key: 'uploaded_by',
    title: <FormattedMessage id="label.uploaded_by" />,
    orderBy: myocean__routes__wallets__wallet__OrderFields.UPLOADED_BY_ID,
    dataTestId: 'table-column-head-uploaded-by',
    width: 200,
  },
  {
    key: 'currency',
    title: (
      <Tooltip title={<FormattedMessage id="table.header.currency" />} placement="top" arrow>
        <div>
          <FormattedMessage id="table.header.currency_short" />
        </div>
      </Tooltip>
    ),
    width: 54,
    align: 'center',
  },
  {
    key: 'expenses',
    title: <FormattedMessage id="table.header.expenses" />,
    align: 'right',
    width: 142,
  },
  {
    title: '',
    key: 'actions',
    width: 108,
  },
];
