import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  dropzone: {
    border: `1px solid ${COLOURS.Coconut.Solid[200]}`,
    background: COLOURS.Coconut.Solid[100],
    borderRadius: 1,
    cursor: 'pointer',
    height: 118,
  },
});
