import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'components/Intl';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import selector from './selector';

const ViewRequestPanelContentInfo = () => {
  const { createdAt, createdBy, description } = useAppSelector(selector);

  return (
    <Grid item container direction="column" gap={6} wrap="nowrap">
      <Grid item container gap={3} wrap="nowrap">
        <Grid item container direction="column" gap={2} width={130}>
          <Typography textTransform="uppercase" fontSize={12} color={COLOURS.Coconut.Solid[600]}>
            <FormattedMessage id="label.created_at" />
          </Typography>

          {createdAt}
        </Grid>

        <Grid item container direction="column" gap={2}>
          <Typography textTransform="uppercase" fontSize={12} color={COLOURS.Coconut.Solid[600]}>
            <FormattedMessage id="label.creator" />
          </Typography>

          {createdBy}
        </Grid>
      </Grid>

      <Grid item container wrap="nowrap">
        <Grid item container direction="column" gap={2}>
          <Typography textTransform="uppercase" fontSize={12} color={COLOURS.Coconut.Solid[600]}>
            <FormattedMessage id="label.description" />
          </Typography>

          {description}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewRequestPanelContentInfo;
