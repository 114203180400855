import Immutable from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { OrderWalletsFields, WalletOutputSchema } from '@/generated';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface IWalletsListState extends List<WalletOutputSchema> {
  orderBy: Nullable<OrderWalletsFields>;
  order: SORT_ORDER_ENUM;
  paginationPage: number;
}

const defaultState = Immutable<IWalletsListState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  orderBy: OrderWalletsFields.AMOUNT,
  order: SORT_ORDER_ENUM.DESC,
  paginationPage: 0,
});

export default handleActions<typeof defaultState, unknown>(
  {
    [actions.setOrderBy.toString()]: (state, { payload }) => {
      return state.set('orderBy', payload);
    },
    [actions.setOrder.toString()]: (state, { payload }) => {
      return state.set('order', payload);
    },
    [actions.setPaginationPage.toString()]: (state, { payload }) => {
      return state.set('paginationPage', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
