import Immutable from '@core-ui/immutable';
import reducer, { Data } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { EMPTY_REQUEST } from 'pages/backoffice/Requests/consts';
import { ICreateEditRequestForm } from 'pages/backoffice/Requests/types';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';

export interface ICreateEditRequestPanelState extends Data<ICreateEditRequestForm> {
  disabled: boolean;
  initialValue: Nullable<ICreateEditRequestForm>;
  // array of files to be uploaded to the Request, it's entries can be deleted
  files: File[];
  // array of names of files already uploaded to the Request, it's entries can't be deleted since files can't be deleted from a Request
  filenames: string[];
}

const defaultState = Immutable<ICreateEditRequestPanelState>({
  disabled: false,
  initialValue: null,
  files: [],
  filenames: [],
  value: EMPTY_REQUEST,
  loaded: false,
  hasData: false,
  loading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setCreateEditRequestDisabled.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('disabled', payload);
    },
    [actions.setCreateEditRequestInitialValue.toString()]: (
      state,
      { payload }: Action<Nullable<ICreateEditRequestForm>>
    ) => {
      return state.set('initialValue', payload);
    },
    [actions.addCreateEditRequestFiles.toString()]: (state, { payload }: Action<File[]>) => {
      const currentFiles = state.files;

      return state.set('files', [...currentFiles, ...payload]);
    },
    [actions.clearCreateEditRequestFiles.toString()]: (state) => {
      return state.set('files', []);
    },
    [actions.deleteCreateEditRequestFileByIndex.toString()]: (state, { payload }: Action<number>) => {
      const filteredFiles = state.files.filter((_, idx) => idx !== payload);

      return state.set('files', filteredFiles);
    },
    [actions.setCreateEditRequestFilenames.toString()]: (state, { payload }: Action<string[]>) => {
      return state.set('filenames', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
