import { COLOURS, TRANSITION_TIMING_FUNCTION } from '@core-ui/styles';
import {
  SIDE_REQUEST_PANEL_DEFAULT_WIDTH,
  SIDE_REQUEST_PANEL_TRANSITION_DURATION,
} from 'pages/backoffice/Requests/consts';

export default (): Record<string, any> => ({
  resizableStyles: {
    height: '100%',
    transition: `margin-right ${SIDE_REQUEST_PANEL_TRANSITION_DURATION}ms ${TRANSITION_TIMING_FUNCTION}, border ${SIDE_REQUEST_PANEL_TRANSITION_DURATION}ms ${TRANSITION_TIMING_FUNCTION}`,
    marginRight: -4,
    borderRight: 'none',
    borderLeft: `1px solid ${COLOURS.Coconut.Solid[200]}`,
    backgroundColor: COLOURS.WHITE,
    zIndex: 1,
  },

  transitionStyles: {
    entering: { marginRight: `-${SIDE_REQUEST_PANEL_DEFAULT_WIDTH}px` },
    entered: { marginRight: `-${SIDE_REQUEST_PANEL_DEFAULT_WIDTH}px` },
    exiting: { marginRight: 0 },
    exited: { marginRight: 0 },
  },

  disabledHandleStyles: {
    cursor: 'default',
  },
});
