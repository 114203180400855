import { RequestDetailOutputV2Schema } from '@/generated';
import Immutable from '@core-ui/immutable';
import reducer, { Data } from '@core-ui/reducers_handlers';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';
import { IViewRequestPanelAddFiles } from './actions';

export interface IViewRequestPanelState extends Data<RequestDetailOutputV2Schema> {
  disabled: boolean;
}

const defaultState = Immutable<IViewRequestPanelState>({
  disabled: false,
  loaded: false,
  hasData: false,
  loading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setSingleRequestDisabled.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('disabled', payload);
    },
    [actions.addFilesToRequest.toString()]: (state, { payload }: Action<IViewRequestPanelAddFiles>) => {
      const { newFiles } = payload;

      const currentFiles = state.value?.files_tree;
      const newFilesTree = [...(currentFiles ?? []), ...newFiles];

      return state.setIn(['value', 'files_tree'], newFilesTree);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
